import { N } from '@mobily/ts-belt';
import { TTLinkProfile } from '@netinsight/crds-timetransfer';
import { CombinedLinkDetailMetrics } from './getCombinedLinkDetailMetrics';
import { fromMicroseconds, toMicroseconds } from './duration';

export const getDelayToAndFromRemote = ({
  roundtripTime,
  delayDifference,
}: Pick<TTLinkProfile, 'roundtripTime' | 'delayDifference'>): Pick<
  TTLinkProfile,
  'delayFromRemote' | 'delayToRemote'
> => {
  return {
    delayToRemote: roundtripTime / 2 - delayDifference / 2,
    delayFromRemote: roundtripTime / 2 + delayDifference / 2,
  };
};

export const getRoundTripTimeAndDelayDifference = ({
  delayToRemote,
  delayFromRemote,
}: Pick<TTLinkProfile, 'delayToRemote' | 'delayFromRemote'>): Pick<
  TTLinkProfile,
  'roundtripTime' | 'delayDifference'
> => {
  return {
    roundtripTime: delayFromRemote + delayToRemote,
    delayDifference: delayFromRemote - delayToRemote,
  };
};

export const equalValues = (x: number, y: number, tolerance = 5.5e-4) => Math.abs(x - y) < tolerance;

export const getCanCalculate = ({ rtt }: CombinedLinkDetailMetrics) => typeof rtt === 'number' && !isNaN(rtt);

export const calculateProfileFromMetrics = (
  { pathDiff, linkTimeError, rtt }: CombinedLinkDetailMetrics,
  assumeSymmetry: boolean,
): Pick<TTLinkProfile, 'roundtripTime' | 'delayDifference' | 'delayToRemote' | 'delayFromRemote'> | undefined => {
  if (typeof rtt !== 'number' || isNaN(rtt)) {
    return undefined;
  }
  let calculatedDelayDifference: number;
  if (!assumeSymmetry) {
    if (typeof linkTimeError !== 'number' || isNaN(linkTimeError)) {
      return undefined;
    }
    calculatedDelayDifference = N.clamp(
      fromMicroseconds(pathDiff ?? 0) + linkTimeError * 2,
      -Math.abs(rtt),
      +Math.abs(rtt),
    );
  } else {
    calculatedDelayDifference = 0;
  }
  const roundtripTime = toMicroseconds(rtt);
  const delayDifference = toMicroseconds(calculatedDelayDifference);
  const { delayToRemote, delayFromRemote } = getDelayToAndFromRemote({
    roundtripTime,
    delayDifference: delayDifference,
  });
  return { roundtripTime, delayDifference, delayFromRemote, delayToRemote };
};
