import { useCallback, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { A, F, G, pipe } from '@mobily/ts-belt';
import { LogLevelCommon } from '@netinsight/crds-common';
import {
  DEFAULT_GNSS_CONTROLLER_CONFIG,
  DefaultPTPTelemetryInterval,
  TimeTransferSpec,
  ptpReceiverDisplayName,
  ptpTransmitterDisplayName,
  toNodeManagerLogLevel,
} from '@netinsight/crds-timetransfer';
import {
  useNodeManagerConfig,
  useSnackbarHelper,
  useTimeTransferConfig,
  useTimeTransferConfigUpdate,
  useNodeManagerConfigUpdate,
} from '@netinsight/management-app-common-react';
import { NodeManagerConfig } from '@netinsight/management-app-common-api';
import { useInterfaceInfos } from '@netinsight/plugin-node-manager-ui';
import { ptpReceiverName, ptpTransmitterName } from '@netinsight/plugin-ptp-config-ui';

const getServiceLoggingConfig = (
  syncdConfig?: TimeTransferSpec,
  nodeManagerConfig?: NodeManagerConfig,
  interfaceNameMap?: Record<string, string>,
) => {
  return {
    syncd: syncdConfig?.syncd?.logLevel,
    tsProcTelemetry: syncdConfig?.syncd?.tsProcTelemetry,
    hicc: syncdConfig?.hicc?.logLevel,
    nodeManager: nodeManagerConfig?.debug?.logLevel as LogLevelCommon,
    ptpTransmitters:
      syncdConfig?.ptpGm?.instances?.map(ptpInstance => ({
        displayName: ptpInstance.displayName ?? ptpTransmitterDisplayName(ptpInstance.interface, interfaceNameMap),
        containerNames: [
          ptpTransmitterName(ptpInstance.interface),
          `ptpctld-trans-${ptpInstance.interface?.replaceAll('.', '-')}`,
        ],
        logLevel: ptpInstance.logLevel,
        controllerLogLevel: ptpInstance.controllerLogLevel,
        telemetryEnableP2PCounters: ptpInstance.telemetryEnableP2PCounters,
        telemetryInterval: ptpInstance.telemetryInterval,
      })) ?? [],
    ptpReceivers:
      syncdConfig?.ptpReceiver?.instances?.map((ptpInstance, index) => ({
        displayName: ptpInstance.displayName ?? ptpReceiverDisplayName(index),
        containerNames: [ptpReceiverName(index), `ptpctld-recv-${index + 1}`],
        logLevel: ptpInstance.logLevel,
        controllerLogLevel: ptpInstance.controllerLogLevel,
        telemetryEnableP2PCounters: ptpInstance.telemetryEnableP2PCounters,
        telemetryInterval: ptpInstance.telemetryInterval,
      })) ?? [],
    gnss: syncdConfig?.gnss?.logLevel,
    gnssctld: syncdConfig?.gnss?.controller?.logLevel,
    gnssctldFixTelemetryRate: syncdConfig?.gnss?.controller?.telemetryRate?.fix,
    gnssctldPerSatelliteTelemetryRate: syncdConfig?.gnss?.controller?.telemetryRate?.perSatellite,
    gnssctldStatusTelemetryRate: syncdConfig?.gnss?.controller?.telemetryRate?.status,
    gnssctldSystemTelemetryRate: syncdConfig?.gnss?.controller?.telemetryRate?.system,
    gnssctldTraimTelemetryRate: syncdConfig?.gnss?.controller?.telemetryRate?.traim,
  };
};

export type ServiceLoggingConfiguration = ReturnType<typeof getServiceLoggingConfig>;

export const useServiceLogging = (nodeId: string) => {
  const { data: syncdConfig, isLoading: isSyncdConfigLoading, error: syncdConfigError } = useTimeTransferConfig(nodeId);
  const {
    config: nodeManagerConfig,
    loading: isNodeManagerConfigLoading,
    error: nodeManagerConfigError,
  } = useNodeManagerConfig(nodeId);
  const { data: interfaceInfos, isLoading: isLoadingInterfaces } = useInterfaceInfos({
    nodeId,
    includeVlan: true,
    includeManagement: true,
    statuses: [],
  });
  const interfaceNameMap = useMemo(
    () => Object.fromEntries(interfaceInfos?.map(iface => [iface.id, iface.displayName]) ?? []),
    [interfaceInfos],
  );
  const { trigger: updateTimeTransferConfig } = useTimeTransferConfigUpdate(nodeId, false);
  const { trigger: updateNodeManagerConfig } = useNodeManagerConfigUpdate(nodeId, false);
  const { snackbar } = useSnackbarHelper();

  const handleFormSubmit: SubmitHandler<ServiceLoggingConfiguration> = useCallback(
    async logLevels => {
      try {
        await Promise.all([
          updateTimeTransferConfig(existingTimeTransferConfig => ({
            ...existingTimeTransferConfig,
            syncd: {
              ...(existingTimeTransferConfig.syncd ?? {}),
              logLevel: logLevels.syncd,
              tsProcTelemetry: logLevels.tsProcTelemetry,
            },
            hicc: {
              ...(existingTimeTransferConfig.hicc ?? {}),
              logLevel: logLevels.hicc,
            },
            gnss: {
              ...(existingTimeTransferConfig.gnss ?? {}),
              logLevel: logLevels.gnss,
              controller: {
                ...(existingTimeTransferConfig.gnss?.controller ?? DEFAULT_GNSS_CONTROLLER_CONFIG),
                logLevel: logLevels.gnssctld ?? 'info',
                telemetryRate: {
                  ...(existingTimeTransferConfig.gnss?.controller?.telemetryRate ?? {}),
                  fix: logLevels.gnssctldFixTelemetryRate,
                  perSatellite: logLevels.gnssctldPerSatelliteTelemetryRate,
                  status: logLevels.gnssctldStatusTelemetryRate,
                  system: logLevels.gnssctldSystemTelemetryRate,
                  traim: logLevels.gnssctldTraimTelemetryRate,
                },
              },
            },
            ptpReceiver: {
              ...existingTimeTransferConfig.ptpReceiver,
              instances: pipe(
                existingTimeTransferConfig?.ptpReceiver?.instances ?? [],
                A.zipWith(logLevels.ptpReceivers, (existingCfg, formCfg) => ({
                  ...existingCfg,
                  controllerLogLevel: formCfg.controllerLogLevel,
                  telemetryInterval:
                    G.isNullable(existingCfg.telemetryInterval) &&
                    formCfg.telemetryInterval === DefaultPTPTelemetryInterval
                      ? undefined
                      : formCfg.telemetryInterval,
                  telemetryEnableP2PCounters: formCfg.telemetryEnableP2PCounters,
                })),
                F.toMutable,
              ),
            },
            ptpGm: {
              ...(existingTimeTransferConfig.ptpGm ?? { delayNs: 0 }),
              instances: pipe(
                existingTimeTransferConfig?.ptpGm?.instances ?? [],
                A.zipWith(logLevels.ptpTransmitters, (existingCfg, formCfg) => ({
                  ...existingCfg,
                  controllerLogLevel: formCfg.controllerLogLevel,
                  telemetryInterval:
                    G.isNullable(existingCfg.telemetryInterval) &&
                    formCfg.telemetryInterval === DefaultPTPTelemetryInterval
                      ? undefined
                      : formCfg.telemetryInterval,
                  telemetryEnableP2PCounters: formCfg.telemetryEnableP2PCounters,
                })),
                F.toMutable,
              ),
            },
          })),
          updateNodeManagerConfig(existingNodeManagerConfig => ({
            ...existingNodeManagerConfig,
            debug: {
              ...existingNodeManagerConfig.debug,
              logLevel: toNodeManagerLogLevel(logLevels.nodeManager),
            },
          })),
        ]);
        snackbar.notifySuccess('Update');
      } catch (error) {
        snackbar.notifyError('Update', null, '');
      }
    },
    [updateNodeManagerConfig, updateTimeTransferConfig, snackbar],
  );

  const config = useMemo(
    () => getServiceLoggingConfig(syncdConfig, nodeManagerConfig, interfaceNameMap ?? {}),
    [syncdConfig, nodeManagerConfig, interfaceNameMap],
  );

  return {
    config,
    isLoading: isSyncdConfigLoading || isNodeManagerConfigLoading || isLoadingInterfaces,
    error: syncdConfigError ?? nodeManagerConfigError,
    update: handleFormSubmit,
  };
};
