import React from 'react';
import { cloneDeep, isEqual } from 'lodash';
import {
  Grid,
  Box,
  Paper,
  Slider,
  Switch,
  Button,
  List,
  FormControl,
  FormControlLabel,
  ListItem,
  ListItemText,
  Card,
  CardActions,
  CardContent,
  TextField,
  InputAdornment,
  makeStyles,
  Typography,
  Input,
  InputLabel,
  Divider,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/CloseOutlined';
import {
  ConfirmButton,
  FeatureFlags,
  useFormStyles,
  useNodeNameMap,
  useSnackbarHelper,
} from '@netinsight/management-app-common-react';
import { useGroupCalibrationSpec, useGroupCalibrationSpecUpdate } from '../../hooks';
import { GroupCalibrationPreset, GroupCalibrationScheduleV2 } from '@netinsight/group-calibration-api';
import { useSyncRegions } from '@netinsight/plugin-sync-region-ui';
import { FeatureFlagged } from '@backstage/core-app-api';
import { D } from '@mobily/ts-belt';

const DrawerWidth = 240;

type SelectItem = { label: string; id: string };

const DefaultMaxAdjustmentNs = 500;
const SecToNs = 1e9;
const NsToSec = 1e-9;

const BaseNodeGroups: SelectItem[] = [{ label: 'All Nodes', id: 'all' }];

const IntervalLabels = [
  { id: 'off', label: 'Off' },
  { id: '30m', label: '30 m' },
  { id: '1h', label: '1 h' },
  { id: '2h', label: '2 h' },
  { id: '3h', label: '3 h' },
  { id: '6h', label: '6 h' },
  { id: '12h', label: '12 h' },
  { id: '24h', label: '24 h' },
];

const IntervalMarks = IntervalLabels.map(({ label }, index) => ({ value: index, label }));

const SpanMarks = Array.from({ length: 25 }).map((_, i) => ({ value: i, label: `${i < 10 ? '0' : ''}${i}` }));

const intervalToSlider = (interval: GroupCalibrationScheduleV2['interval'] | undefined): number =>
  IntervalLabels.findIndex(item => item.id === interval);

const spanToSlider = (span: [number, number] | undefined): number[] => {
  if (!span) {
    return [0, 24];
  }
  return span;
};

const useStyles = makeStyles(() => ({
  menu: {
    padding: 16,
  },
  menuItem: {
    padding: 0,
  },
  actions: {
    justifyContent: 'flex-start',
  },
}));

type RegionLabel = { id: string; label: string; region: string };
type GroupCalibrationPresetFormValues = GroupCalibrationPreset & { maxAdjustmentNs?: number };

export const GroupCalibrationPresets = () => {
  const classes = useStyles();
  const formStyles = useFormStyles();
  const [interval, setInterval] = React.useState<number>(0);
  const [currentPreset, setCurrentPreset] = React.useState<GroupCalibrationPresetFormValues | null>(null);
  const [original, setOriginal] = React.useState<GroupCalibrationPreset | null>(null);
  const { data: spec } = useGroupCalibrationSpec();
  const { trigger: updateSpec } = useGroupCalibrationSpecUpdate();
  const { data: nodeNameMap } = useNodeNameMap();
  const { data: syncRegions } = useSyncRegions();
  const { snackbar } = useSnackbarHelper();
  const [includeOptions, setIncludeOptions] = React.useState<RegionLabel[]>([]);
  const [nodeGroups, setNodeGroups] = React.useState<SelectItem[]>([]);

  const isModified = () => original === null || !isEqual(currentPreset, original);

  const handleChangeCurrentPreset = (preset: GroupCalibrationPreset | null) => {
    setCurrentPreset(
      preset === null
        ? null
        : {
            ...cloneDeep(preset),
            maxAdjustmentNs:
              typeof preset.maxAdjustment !== 'undefined'
                ? Math.round(preset.maxAdjustment * SecToNs)
                : DefaultMaxAdjustmentNs,
          },
    );
    setOriginal(preset);
  };

  React.useEffect(() => {
    const nodeIdToRegion = (() => {
      const ret = [];
      const regions = syncRegions ?? [];
      for (const region of regions) {
        for (const id of region.nodeIds) {
          ret.push([id, region.name]);
        }
      }
      return Object.fromEntries(ret);
    })();

    setNodeGroups(
      (() => {
        const syncRegionGroups =
          syncRegions?.map(({ name }) => ({ id: name, label: `Sync Region: ${name}` }) as SelectItem) ?? [];
        return [...BaseNodeGroups, ...syncRegionGroups];
      })(),
    );

    const allNodesOption = (nameMap: object) => Object.entries(nameMap).map(([id, label]) => ({ id, label }));

    const includeNodeList = () => {
      if (currentPreset === null) return [];
      const ret = allNodesOption(nodeNameMap ?? {}).map(node => ({ ...node, region: nodeIdToRegion[node.id] ?? '' }));
      return ret.sort((a, b) => {
        const region = a.region.localeCompare(b.region);
        if (region === 0) return a.label.localeCompare(b.label);
        return region;
      });
    };

    setInterval(intervalToSlider(currentPreset?.schedule?.interval));
    setIncludeOptions(includeNodeList());
  }, [currentPreset, syncRegions, nodeNameMap]);

  const handleChangeName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (currentPreset) {
        setCurrentPreset({ ...currentPreset, name: event.target.value });
      }
    },
    [currentPreset],
  );

  const handleChangeNodegroup = React.useCallback(
    (_event: any, value: { id: string; label: string }[] | null) => {
      if (currentPreset && value) {
        setCurrentPreset({ ...currentPreset, nodeGroups: value.map(item => item.id) });
      }
    },
    [currentPreset],
  );

  const handleChangeInclude = React.useCallback(
    (_event: any, value: { id: string; label: string }[] | null) => {
      if (currentPreset && value) {
        setCurrentPreset({ ...currentPreset, includeNodes: value.map(node => node.id) });
      }
    },
    [currentPreset],
  );

  const handleChangeExclude = React.useCallback(
    (_event: any, value: { id: string; label: string }[] | null) => {
      if (currentPreset && value) {
        setCurrentPreset({ ...currentPreset, excludeNodes: value.map(node => node.id) });
      }
    },
    [currentPreset],
  );

  const invertedSpan = React.useCallback(() => {
    const span = currentPreset?.schedule?.span;
    return span && span[0] > span[1];
  }, [currentPreset]);

  const invertSpan = React.useCallback(() => {
    if (currentPreset?.schedule?.span) {
      const span: [number, number] = [currentPreset.schedule.span[1], currentPreset.schedule.span[0]];
      setCurrentPreset({ ...currentPreset, schedule: { ...currentPreset.schedule, span } });
    }
  }, [currentPreset]);

  const handleChangeMaxAdjustment = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (currentPreset) {
        const valueNS = event.target.value === '' ? undefined : parseFloat(event.target.value);
        setCurrentPreset({ ...currentPreset, maxAdjustmentNs: valueNS });
      }
    },
    [currentPreset],
  );

  const handleAdjustmentLimit = React.useCallback(
    (_event: any, checked: boolean) => {
      if (currentPreset) {
        setCurrentPreset({
          ...currentPreset,
          limitMaxAdjustment: checked,
          ...(checked ? { maxAdjustment: Math.max(currentPreset.maxAdjustment ?? 0, 500 / 1e9) } : {}),
        });
      }
    },
    [currentPreset],
  );

  const handleChangeRequireReferences = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (currentPreset) {
        setCurrentPreset({ ...currentPreset, requireReferences: event.target.checked });
      }
    },
    [currentPreset],
  );

  const handleChangeLimitPathdiffToRtt = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (currentPreset) {
        setCurrentPreset({ ...currentPreset, limitPathdiffToRtt: event.target.checked });
      }
    },
    [currentPreset],
  );

  const handleCalibrateUnusedLinks = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (currentPreset) {
        setCurrentPreset({ ...currentPreset, calibrateUnusedLinks: event.target.checked });
      }
    },
    [currentPreset],
  );

  const handleConstrainedCalibration = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (currentPreset) {
        setCurrentPreset({ ...currentPreset, constrained: event.target.checked });
      }
    },
    [currentPreset],
  );

  const handleChangeScheduleCommit = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (currentPreset?.schedule) {
        setCurrentPreset({ ...currentPreset, schedule: { ...currentPreset.schedule, commit: event.target.checked } });
      }
    },
    [currentPreset],
  );

  const handleRemovePreset = React.useCallback(async () => {
    if (spec && original) {
      const specPresetIndex = spec.presets.findIndex(elem => elem.name === original.name);
      if (specPresetIndex > 0) {
        await updateSpec({
          ...spec,
          presets: spec.presets.filter((_, i) => i !== specPresetIndex),
        });
        snackbar.info(`Removed group ${currentPreset?.name ?? ''}`);
      }
      setCurrentPreset(null);
    }
  }, [spec, original, currentPreset, updateSpec, snackbar]);

  const handleDiscardNewPreset = () => setCurrentPreset(null);

  const validateName = React.useMemo(() => {
    if (currentPreset === null || currentPreset.name.length < 1) {
      return 'Name is required';
    }
    if (!/^[A-Za-z0-9 ]*$/.test(currentPreset.name)) {
      return 'Name contains unrecognized symbols';
    }
    const presets = spec?.presets ?? [];
    if (original === null) {
      if (presets.filter(other => currentPreset.name === other.name).length > 0) {
        return 'Name must be unique';
      }
    } else if (presets.filter(other => original.name !== other.name && currentPreset.name === other.name).length > 0) {
      return 'Name must be unique';
    }
    return null;
  }, [currentPreset, spec, original]);

  const savePreset = React.useCallback(async () => {
    if (spec && currentPreset && validateName === null) {
      if (currentPreset.schedule?.interval === 'off') {
        currentPreset.schedule.span = undefined;
        currentPreset.schedule.commit = undefined;
      }
      const updatedPreset = D.deleteKey(
        { ...currentPreset, maxAdjustment: (currentPreset.maxAdjustmentNs ?? DefaultMaxAdjustmentNs) * NsToSec },
        'maxAdjustmentNs',
      );
      if (original === null) {
        const newSpec = await updateSpec({
          ...spec,
          presets: [...spec.presets, updatedPreset],
        });
        setOriginal(newSpec.presets[newSpec.presets.length - 1]);
      } else {
        const idx = spec.presets.findIndex(item => item.name === original.name);
        const newPresets = [...spec.presets];
        newPresets.splice(idx, 1, updatedPreset);
        const newSpec = await updateSpec({ ...spec, presets: newPresets });
        setOriginal(newSpec.presets[idx]);
      }
      snackbar.success(`Saved group ${currentPreset?.name ?? ''}`);
    }
  }, [spec, currentPreset, original, updateSpec, validateName, snackbar]);

  const handleNewPreset = () => {
    setOriginal(null);
    setCurrentPreset({
      name: 'New Group',
      nodeGroups: ['all'],
      maxAdjustmentNs: DefaultMaxAdjustmentNs,
    });
  };

  const handleScheduleInterval = React.useCallback(
    (_event: any, newValue: number | number[]) => {
      const intervalIndex = newValue as number;
      setInterval(intervalIndex);
      if (currentPreset) {
        let newInterval: GroupCalibrationScheduleV2['interval'];
        if (intervalIndex >= 0 && intervalIndex < IntervalLabels.length) {
          newInterval = IntervalLabels[intervalIndex].id as any;
        } else {
          throw new Error(`Unexpected interval index ${newValue}`);
        }
        setCurrentPreset({
          ...currentPreset,
          schedule: { ...(currentPreset.schedule ? currentPreset.schedule : {}), interval: newInterval },
        });
      }
    },
    [currentPreset],
  );

  const handleScheduleSpan = React.useCallback(
    (_event: any, newValue: number[]) => {
      if (currentPreset?.schedule) {
        const [larger, smaller] = newValue[0] > newValue[1] ? [newValue[0], newValue[1]] : [newValue[1], newValue[0]];
        const span: [number, number] = invertedSpan() ? [larger, smaller] : [smaller, larger];
        setCurrentPreset({
          ...currentPreset,
          schedule: { ...currentPreset.schedule, span },
        });
      }
    },
    [currentPreset, invertedSpan],
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="nav" sx={{ width: { sm: DrawerWidth }, flexShrink: { sm: 0 } }}>
        <Paper className={classes.menu} elevation={2}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleNewPreset}
            disabled={original === null && currentPreset !== null}
          >
            Add Group
          </Button>
          <List dense>
            {original === null && currentPreset !== null && (
              <ListItem key={currentPreset?.name} className={classes.menuItem}>
                <ListItem button selected disabled>
                  <ListItemText primary={`*${currentPreset?.name}*`} />
                </ListItem>
              </ListItem>
            )}
            {(spec?.presets ?? []).map(preset => (
              <ListItem key={preset.name} className={classes.menuItem}>
                <ListItem
                  button
                  selected={original?.name === preset.name}
                  onClick={_event => handleChangeCurrentPreset(preset)}
                >
                  <ListItemText primary={preset.name} />
                </ListItem>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${DrawerWidth}px)` } }}>
        {currentPreset !== null && (
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="preset-name"
                    value={currentPreset.name}
                    label="Name"
                    variant="standard"
                    disabled={original !== null && original === spec?.presets[0]}
                    onChange={handleChangeName}
                    error={validateName !== null}
                    helperText={validateName ?? ''}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="preset-nodegroups"
                    value={
                      currentPreset.nodeGroups
                        .map(name => nodeGroups.find(item => item.id === name))
                        .filter(item => item !== undefined) as SelectItem[]
                    }
                    options={nodeGroups}
                    getOptionLabel={option => option.label}
                    getOptionSelected={(option, value) => option.id === value.id}
                    limitTags={3}
                    onChange={handleChangeNodegroup}
                    renderInput={params => <TextField {...params} variant="standard" label="Base" />}
                  />
                </Grid>
                {!currentPreset.nodeGroups.includes('all') && (
                  <Grid item xs={6}>
                    <Autocomplete
                      multiple
                      id="preset-include-nodes"
                      value={includeOptions.filter(node => (currentPreset.includeNodes ?? []).includes(node.id))}
                      options={includeOptions}
                      groupBy={option => option.region}
                      getOptionLabel={option => option.label}
                      getOptionSelected={(option, value) => option.id === value.id}
                      limitTags={3}
                      onChange={handleChangeInclude}
                      renderInput={params => (
                        <TextField {...params} variant="standard" label="Include" InputLabelProps={{ shrink: true }} />
                      )}
                    />
                  </Grid>
                )}
                {currentPreset.nodeGroups.length > 0 && (
                  <Grid item xs={6}>
                    <Autocomplete
                      multiple
                      id="preset-exclude-nodes"
                      value={includeOptions.filter(node => (currentPreset.excludeNodes ?? []).includes(node.id))}
                      options={includeOptions}
                      groupBy={option => option.region}
                      getOptionLabel={option => option.label}
                      getOptionSelected={(option, value) => option.id === value.id}
                      limitTags={3}
                      onChange={handleChangeExclude}
                      renderInput={params => (
                        <TextField {...params} variant="standard" label="Exclude" InputLabelProps={{ shrink: true }} />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      label="Limit max allowed link adjustment"
                      checked={currentPreset.limitMaxAdjustment !== undefined ? currentPreset.limitMaxAdjustment : true}
                      onChange={handleAdjustmentLimit}
                    />
                    <FormControl style={{ margin: 1, width: '25ch' }}>
                      <InputLabel htmlFor="preset-max-adjustment" shrink>
                        Max adjustment
                      </InputLabel>
                      <Input
                        id="preset-max-adjustment"
                        endAdornment={<InputAdornment position="end">ns</InputAdornment>}
                        value={currentPreset.maxAdjustmentNs ?? ''}
                        placeholder={DefaultMaxAdjustmentNs.toString()}
                        disabled={!(currentPreset?.limitMaxAdjustment ?? true)}
                        aria-describedby="max-adjustment-text"
                        type="number"
                        inputProps={{
                          'aria-label': 'max-adjustment',
                          min: 0,
                        }}
                        onChange={handleChangeMaxAdjustment}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label="Require references on all nodes"
                    control={
                      <Switch
                        id="preset-require-references"
                        checked={currentPreset?.requireReferences ?? true}
                        aria-describedby="require-references-text"
                        inputProps={{
                          'aria-label': 'require-references',
                        }}
                        onChange={handleChangeRequireReferences}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label="Limit path difference adjustment to RTT"
                    control={
                      <Switch
                        id="preset-limit-pathdiff-to-rtt"
                        checked={currentPreset?.limitPathdiffToRtt ?? false}
                        onChange={handleChangeLimitPathdiffToRtt}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label="Calibrate unused links"
                    control={
                      <Switch
                        id="preset-calibrate-unused-links"
                        checked={currentPreset?.calibrateUnusedLinks ?? false}
                        inputProps={{
                          'aria-label': 'calibrate-unused-links',
                        }}
                        onChange={handleCalibrateUnusedLinks}
                      />
                    }
                  />
                </Grid>
                <FeatureFlagged with={FeatureFlags.ShowExperimentalFeatures}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Constrained calibration"
                      control={
                        <Switch
                          id="preset-calibrate-constrained"
                          checked={currentPreset?.constrained ?? false}
                          inputProps={{
                            'aria-label': 'constrained',
                          }}
                          onChange={handleConstrainedCalibration}
                        />
                      }
                    />
                  </Grid>
                </FeatureFlagged>
                <Grid item xs={10}>
                  <Divider />
                  <Typography variant="h6">Schedule</Typography>
                  <Typography variant="caption" gutterBottom>
                    Configure an automatic calibration schedule for a group of nodes. The calculated link adjustments
                    will only be applied if the commit option is selected, else no changes are made. Calibrations will
                    be run at the start time of the Time Span selection and then at every Interval up to and including
                    the end of the Time Span selection.
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography id="calibration-schedule-interval-label" gutterBottom>
                    Interval
                  </Typography>
                  <Slider
                    id="nodegroup-interval-slider"
                    value={interval}
                    onChange={handleScheduleInterval}
                    min={0}
                    max={IntervalMarks.length - 1}
                    step={null}
                    track={false}
                    aria-labelledby="calibration-schedule-interval-label"
                    valueLabelDisplay="off"
                    marks={IntervalMarks}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography id="calibration-schedule-span-label" gutterBottom>
                    Time Span (UTC)
                  </Typography>
                  <Slider
                    id="nodegroup-timespan-slider"
                    value={spanToSlider(currentPreset?.schedule?.span)}
                    onChange={handleScheduleSpan as any}
                    step={null}
                    aria-labelledby="calibration-schedule-span-label"
                    valueLabelDisplay="auto"
                    min={0}
                    max={24}
                    track={invertedSpan() ? 'inverted' : undefined}
                    marks={SpanMarks}
                    disabled={[undefined, 'off'].includes(currentPreset?.schedule?.interval)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={invertSpan}
                    disabled={[undefined, 'off'].includes(currentPreset?.schedule?.interval)}
                  >
                    Invert
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label="Commit scheduled calibration results"
                    control={
                      <Switch
                        id="calibration-schedule-commit"
                        checked={currentPreset?.schedule?.commit ?? false}
                        aria-describedby="calibration-schedule-commit-label"
                        onChange={handleChangeScheduleCommit}
                        disabled={[undefined, 'off'].includes(currentPreset?.schedule?.interval)}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className={classes.actions}>
              <div className={formStyles.buttonContainer}>
                <Button variant="contained" color="primary" onClick={savePreset} disabled={!isModified()}>
                  Apply
                </Button>
                {original === null && (
                  <Button startIcon={<RemoveIcon />} onClick={handleDiscardNewPreset}>
                    Discard
                  </Button>
                )}
                {original !== null && (spec?.presets ?? []).length > 0 && original.name !== spec?.presets[0].name && (
                  <ConfirmButton
                    type="button"
                    variant="outlined"
                    startIcon={<RemoveIcon />}
                    onClick={handleRemovePreset}
                    confirmation="This calibration group will be removed, do you want to continue?"
                  >
                    Remove group
                  </ConfirmButton>
                )}
              </div>
            </CardActions>
          </Card>
        )}
      </Box>
    </Box>
  );
};
