import useSwr, { SWRConfiguration } from 'swr';
import { merge } from 'ts-deepmerge';
import { useApi } from '@backstage/core-plugin-api';
import { metricsApiRef } from '@netinsight/management-app-common-react';
import { groupMetricsFromPromise } from '@netinsight/management-app-common-api';

export const useNtpServerStatuses = ({ nodeId }: { nodeId: string }, options?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);

  return useSwr(
    [nodeId, 'useNtpServerStatuses'],
    async ([nodeIdParam]) => {
      const query = (...metrics: string[]) =>
        metrics.map(metric =>
          metricsApi.instantQuery({
            query: `last_over_time(neti_ntp_status_${metric}{nodeid="${nodeIdParam}"}[30s])`,
          }),
        );
      const [
        delayStatuses,
        jitterStatuses,
        offsetStatuses,
        pollIntervalStatuses,
        reachStatuses,
        selectedStatuses,
        stratumStatuses,
      ] = await Promise.allSettled(query('delay', 'jitter', 'offset', 'poll_interval', 'reach', 'selected', 'stratum'));

      return merge(
        groupMetricsFromPromise(
          delayStatuses,
          metrics => metrics.remote,
          val => ({ delay: parseFloat(val) }),
        ),
        groupMetricsFromPromise(
          jitterStatuses,
          metrics => metrics.remote,
          val => ({ jitter: parseFloat(val) }),
        ),
        groupMetricsFromPromise(
          offsetStatuses,
          metrics => metrics.remote,
          val => ({ offset: parseFloat(val) }),
        ),
        groupMetricsFromPromise(
          pollIntervalStatuses,
          metrics => metrics.remote,
          val => ({ pollInterval: parseFloat(val) }),
        ),
        groupMetricsFromPromise(
          reachStatuses,
          metrics => metrics.remote,
          val => ({ reach: parseFloat(val) }),
        ),
        groupMetricsFromPromise(
          selectedStatuses,
          metrics => metrics.remote,
          val => ({ selected: Boolean(parseInt(val, 10)) }),
        ),
        groupMetricsFromPromise(
          stratumStatuses,
          metrics => metrics.remote,
          val => ({ stratum: parseFloat(val) }),
        ),
      );
    },
    options,
  );
};
