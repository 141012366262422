import { scaleOrdinal } from 'd3-scale';
import {
  BasePtpTransmitterInstanceSchema,
  PTP_TRANS_ID_PREFIX,
  PTP_RECEIVER_ID_PREFIX,
  PtpTransmitterConfigSchema,
} from '@netinsight/crds-timetransfer';
import { unionSchemaScale } from '@netinsight/management-app-common-react';

export const getMaxInstances = (availableInterfaces: number) =>
  Math.min(
    availableInterfaces,
    PtpTransmitterConfigSchema.shape.instances.unwrap().innerType()._def.maxLength?.value ?? Number.MAX_SAFE_INTEGER,
  );

export const clockAccuracyScale = unionSchemaScale<number>(BasePtpTransmitterInstanceSchema.shape.clockAccuracy, [
  [0xfe, 'Unknown'],
]).unknown('Reserved');

export const timeSourceScale = unionSchemaScale<number>(BasePtpTransmitterInstanceSchema.shape.timeSource);

export const PortStates = {
  Initializing: 1,
  Faulty: 2,
  Disabled: 3,
  Listening: 4,
  PreMaster: 5,
  Master: 6,
  Passive: 7,
  Uncalibrated: 8,
  TimeReceiver: 9,
};

export const portStateScale = scaleOrdinal(
  [
    PortStates.Initializing,
    PortStates.Faulty,
    PortStates.Disabled,
    PortStates.Listening,
    PortStates.PreMaster,
    PortStates.Master,
    PortStates.Passive,
    PortStates.Uncalibrated,
    PortStates.TimeReceiver,
  ],
  [
    'Initializing',
    'Faulty',
    'Disabled',
    'Listening',
    'PreTransmitter',
    'Transmitter',
    'Passive',
    'Uncalibrated',
    'Receiver',
  ],
).unknown('Unknown');

export const formatPtpUnicastSyncInterval = (syncInterval: number) => {
  const count = 2 ** Math.abs(syncInterval);
  const sign = Math.sign(syncInterval);
  return sign >= 0 && syncInterval !== 0 ? `1 every ${count} seconds` : `${count} per second`;
};

export const ptpReceiverName = (index: number) => `${PTP_RECEIVER_ID_PREFIX}-${index + 1}`;

export const ptpTransmitterName = (ifaceName: string) =>
  `${PTP_TRANS_ID_PREFIX}-${ifaceName.replaceAll('.', '-').replaceAll(' ', '')}`;

export const getPtpTransmitterServiceName = (metrics: any) =>
  metrics.service_name.replaceAll('ptpctld-', `${PTP_TRANS_ID_PREFIX}-`).replaceAll('.', '-').replaceAll(' ', '');
