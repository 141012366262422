import React, { useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import { InfoCard, Progress } from '@backstage/core-components';
import { Button, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { useApiHolder } from '@backstage/core-plugin-api';
import { authApiRefs } from '../../apis';
import { decodeJwt } from 'jose';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export const UserGroupsCard = () => {
  const apiHolder = useApiHolder();
  const [masked, setMasked] = useState(true);

  const { loading, value: groupsRaw } = useAsync(async () => {
    for (const apiRef of authApiRefs) {
      const authApi = apiHolder.get(apiRef);
      const rawJwt = await authApi?.getAccessToken(undefined, { optional: true });
      if (typeof rawJwt !== 'string' || rawJwt.length === 0) {
        continue;
      }
      const insecureClaims = decodeJwt(rawJwt);
      return (insecureClaims.groups as string[]) ?? [];
    }
    return [];
  }, [apiHolder]);

  const groups = useMemo(() => {
    return masked && groupsRaw ? ['*********'] : groupsRaw;
  }, [masked, groupsRaw]);

  return (
    <InfoCard title="User Groups" variant="fullHeight">
      <Grid container direction="column">
        <Grid item xs>
          <List dense>
            {loading && <Progress />}
            {groups?.length === 0 && <Typography variant="body1">No groups</Typography>}
            {groups?.sort().map(group => (
              <ListItem key={group} button>
                <ListItemText primary={group} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            startIcon={masked ? <VisibilityIcon /> : <VisibilityOffIcon />}
            onClick={() => setMasked(!masked)}
          >
            {masked ? 'Show' : 'Hide'}
          </Button>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
