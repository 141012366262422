import React from 'react';
import { UserSettingsAppearanceCard } from '@backstage/plugin-user-settings';
import { Grid } from '@material-ui/core';
import { ProfileCard } from './ProfileCard';
import { UserGroupsCard } from './UserGroupsCard';

export const GeneralSettingsTab = () => {
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} md={6}>
        <ProfileCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <UserSettingsAppearanceCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <UserGroupsCard />
      </Grid>
    </Grid>
  );
};
