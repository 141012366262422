import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { AlertGroup } from '../../types';
import { Avatar, makeStyles, Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { compareSeverity } from '@netinsight/management-app-common-api';
import { Link } from 'react-router-dom';
import { NetiColors } from '../../constants';

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      gap: theme.spacing(0.5),
      alignItems: 'center',
      margin: theme.spacing(1, 2, 1),
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 2),
      borderRadius: '10rem',
    },
    chip: {
      padding: 0,
      margin: 0,
      color: theme.palette.common.white,
      cursor: 'pointer',
    },
    icon: {
      color: `${theme.palette.common.white} !important`,
    },
    chipCritical: {
      backgroundColor: theme.palette.error.main,
    },
    iconCritical: {
      backgroundColor: theme.palette.error.dark,
    },
    chipMajor: {
      backgroundColor: theme.palette.warning.main,
    },
    iconMajor: {
      backgroundColor: theme.palette.warning.dark,
    },
    chipMinor: {
      backgroundColor: NetiColors.yellow.main,
    },
    iconMinor: {
      backgroundColor: NetiColors.yellow.dark,
    },
    chipWarning: {
      backgroundColor: theme.palette.info.main,
    },
    iconWarning: {
      backgroundColor: theme.palette.info.dark,
    },
    chipUnknown: {
      backgroundColor: theme.palette.grey.A200,
    },
    iconUnknown: {
      backgroundColor: theme.palette.grey.A400,
    },
    label: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
  }),
  { name: 'NetiAlarmsSummary' },
);

export const AlarmSummaryChip: FunctionComponent<{
  count: number;
  severity?: string;
  url?: string;
  showIcon?: boolean;
}> = ({ count, severity, showIcon, url }) => {
  const styles = useStyles();
  const getChipStyles = useCallback(
    (chipSeverity?: string) => {
      if (!chipSeverity) {
        return styles.chipUnknown;
      }
      switch (chipSeverity.toLocaleLowerCase()) {
        case 'critical':
          return styles.chipCritical;
        case 'major':
          return styles.chipMajor;
        case 'minor':
          return styles.chipMinor;
        case 'warning':
          return styles.chipWarning;
        default:
          return styles.chipUnknown;
      }
    },
    [styles],
  );

  const getIconSyles = useCallback(
    (chipSeverity?: string) => {
      if (!chipSeverity) {
        return styles.iconUnknown;
      }
      switch (chipSeverity.toLocaleLowerCase()) {
        case 'critical':
          return styles.iconCritical;
        case 'major':
          return styles.iconMajor;
        case 'minor':
          return styles.iconMinor;
        case 'warning':
          return styles.iconWarning;
        default:
          return styles.iconUnknown;
      }
    },
    [styles],
  );
  return (
    <Chip
      component={typeof url === 'string' ? Link : 'span'}
      to={url}
      label={count}
      variant="default"
      className={[styles.chip, getChipStyles(severity)].join(' ')}
      data-testid={`alarms-summary-${severity}`}
      size="small"
      avatar={
        showIcon ? (
          <Avatar
            title={severity}
            variant="circular"
            aria-label={severity}
            className={[styles.icon, getIconSyles(severity)].join(' ')}
          >
            {severity?.charAt(0).toLocaleUpperCase()}
          </Avatar>
        ) : undefined
      }
    />
  );
};

export const AlarmsSummary: FunctionComponent<{ alertGroups: AlertGroup[]; linkTo: string }> = ({
  alertGroups,
  linkTo,
}) => {
  const styles = useStyles();
  const countBySeverity = useMemo(() => {
    return Object.entries(
      alertGroups
        .flatMap(group => group.alerts.map(alert => alert.labels.severity))
        .reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: (acc[curr] ?? 0) + 1,
          }),
          {} as Record<string, number>,
        ),
    ).sort(([severity1], [severity2]) => compareSeverity(severity1, severity2));
  }, [alertGroups]);

  return (
    <div className={styles.container}>
      <Typography component={Link} to={linkTo} variant="button" className={styles.label}>
        {countBySeverity.length > 0 ? 'Alarms' : 'No alarms'}
      </Typography>
      {countBySeverity.map(([severity, count]) => (
        <AlarmSummaryChip key={severity} severity={severity} count={count} url={linkTo} showIcon />
      ))}
    </div>
  );
};
