import { useCallback } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { metricsApiRef } from '@netinsight/management-app-common-react';
import { groupMetricsFromPromise, groupMetricsFromPromiseBy2Levels } from '@netinsight/management-app-common-api';

export const useSingleLinkMetrics = (linkId: string, nodeIds: string[], swrConfig?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);
  const fetcher = useCallback(
    async ([linkIdParam, nodeIdsParam]: [string, string[], string]) => {
      const nodeIdsQuery = nodeIdsParam.join('|');
      const [rttResult, linkTimeErrorResult, linkStableResult, nodeStableResult] = await Promise.allSettled([
        metricsApi.instantQuery({
          query: `avg_over_time(neti_hicc_multilink_filtered_rtt{link_id="${linkIdParam}"}[30s])`,
        }),
        metricsApi.instantQuery({
          query: `avg_over_time(neti_hicc_multilink_link_time_error{link_id="${linkIdParam}"}[30s])`,
        }),
        metricsApi.instantQuery({ query: `neti_hicc_link_stable{link_id="${linkIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_hicc_nodestability_is_stable{nodeid=~"${nodeIdsQuery}"}` }),
      ]);
      const rtts = groupMetricsFromPromiseBy2Levels(
        rttResult,
        m => m.link_id,
        m => m.nodeid,
        val => parseFloat(val),
      );
      const linkTimeErrors = groupMetricsFromPromiseBy2Levels(
        linkTimeErrorResult,
        m => m.link_id,
        m => m.nodeid,
        val => parseFloat(val),
      );

      const linkStable = groupMetricsFromPromiseBy2Levels(
        linkStableResult,
        m => m.link_id,
        m => m.nodeid,
        val => Boolean(parseInt(val, 10)),
      );
      const nodeStable = groupMetricsFromPromise(
        nodeStableResult,
        m => m.nodeid,
        val => Boolean(parseInt(val, 10)),
      );

      return {
        rtts,
        linkTimeErrors,
        linkStable,
        nodeStable,
      };
    },
    [metricsApi],
  );
  return useSWR([linkId, nodeIds, 'useSingleLinkMetrics'], fetcher, swrConfig);
};
