import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { pipe } from '@mobily/ts-belt';
import { getScalarResultFromPromise } from '@netinsight/management-app-common-api';
import { metricsApiRef, formatTimeError, booleanScale } from '@netinsight/management-app-common-react';
import { antennaStatusScale, fixModeScale, fixStatusScale, parseIntBase10, traimSolutionScale } from '../utils';

const CacheKey = 'useGnssStatusMetrics';

export const useGnssStatusMetrics = (nodeId: string, swrConfig?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);
  return useSwr(
    [nodeId, CacheKey],
    async ([nodeIdParam]) => {
      const [
        fixModeResult,
        fixStatusResult,
        antennaStatusResult,
        usedSatsResult,
        visibleSatsResult,
        traimEnabledResult,
        traimSolutionResult,
        traimUsedSatsResult,
        traimRemovedSatsResult,
        traimThresholdResult,
        traimAvgTimeErrResult,
      ] = await Promise.allSettled([
        metricsApi.instantQuery({ query: `neti_gnss_fix_mode{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_fix_status{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_status_antennaStatus{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_status_usedSatellites{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_status_visibleSatellites{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_traim_enabled{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_traim_traimsolution{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_traim_usedsats{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_traim_removedsats{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_traim_threshold{nodeid="${nodeIdParam}"}` }),
        metricsApi.instantQuery({ query: `neti_gnss_traim_avgtimeerr{nodeid="${nodeIdParam}"}` }),
      ]);

      const statuses = {
        ...getScalarResultFromPromise(val => ({ fixMode: pipe(val, parseFloat, fixModeScale) }))(fixModeResult),
        ...getScalarResultFromPromise(val => ({ fixStatus: pipe(val, parseFloat, fixStatusScale) }))(fixStatusResult),
        ...getScalarResultFromPromise(val => ({ antennaStatus: pipe(val, parseIntBase10, antennaStatusScale) }))(
          antennaStatusResult,
        ),
        ...getScalarResultFromPromise(val => ({ usedSats: parseFloat(val) }))(usedSatsResult),
        ...getScalarResultFromPromise(val => ({ visibleSats: parseFloat(val) }))(visibleSatsResult),
        ...getScalarResultFromPromise(val => ({ traimEnabled: pipe(val, parseIntBase10, Boolean, booleanScale) }))(
          traimEnabledResult,
        ),
        ...getScalarResultFromPromise(val => ({ traimSolution: pipe(val, parseIntBase10, traimSolutionScale) }))(
          traimSolutionResult,
        ),
        ...getScalarResultFromPromise(val => ({ traimUsedSats: parseFloat(val) }))(traimUsedSatsResult),
        ...getScalarResultFromPromise(val => ({ traimRemovedSats: parseFloat(val) }))(traimRemovedSatsResult),
        ...getScalarResultFromPromise(val => ({ traimThreshold: parseFloat(val) }))(traimThresholdResult),
        ...getScalarResultFromPromise(val => ({ traimAvgTimeErr: pipe(val, parseFloat, formatTimeError) }))(
          traimAvgTimeErrResult,
        ),
      };

      return statuses;
    },
    swrConfig,
  );
};
