import { SYNC_SOURCE_NAMES, SyncSourceName } from '@netinsight/management-app-common-api';

export const SYNC_SOURCE_LABELS: Record<SyncSourceName, string> = {
  [SYNC_SOURCE_NAMES.gnss]: 'GNSS',
  [SYNC_SOURCE_NAMES.ppsIn]: 'PPS',
  [SYNC_SOURCE_NAMES.osc]: 'Internal oscillator',
  [SYNC_SOURCE_NAMES.syncIn]: '10M/Sync',
  [SYNC_SOURCE_NAMES.ptp1]: 'PTP1',
  [SYNC_SOURCE_NAMES.ptp2]: 'PTP2',
} as const;

export const SYNC_SOURCE_CONFIG_URLS: Record<SyncSourceName, (nodeId: string) => string> = {
  [SYNC_SOURCE_NAMES.gnss]: (nodeId: string) => `/nodes/info/${nodeId}/inputs/gnss`,
  [SYNC_SOURCE_NAMES.ppsIn]: (nodeId: string) => `/nodes/info/${nodeId}/inputs/pps`,
  [SYNC_SOURCE_NAMES.osc]: (nodeId: string) => `/nodes/info/${nodeId}/inputs/holdover`,
  [SYNC_SOURCE_NAMES.syncIn]: (nodeId: string) => `/nodes/info/${nodeId}/inputs/sync-input`,
  [SYNC_SOURCE_NAMES.ptp1]: (nodeId: string) => `/nodes/info/${nodeId}/inputs/ptp`,
  [SYNC_SOURCE_NAMES.ptp2]: (nodeId: string) => `/nodes/info/${nodeId}/inputs/ptp`,
};
