import { ValueOf } from 'type-fest';
import { TimeTransferSpec } from '@netinsight/crds-timetransfer';
import { SyncSourceUsage } from '@netinsight/syncd-schema';
import { SYNC_SOURCE_NAMES } from '../constants';

export const getSyncSourceUsageConfigMap = (
  config?: TimeTransferSpec,
): Record<ValueOf<typeof SYNC_SOURCE_NAMES>, SyncSourceUsage | undefined> => ({
  [SYNC_SOURCE_NAMES.ppsIn]: config?.ppsIn?.usage,
  [SYNC_SOURCE_NAMES.gnss]: config?.gnss?.usage,
  [SYNC_SOURCE_NAMES.syncIn]:
    config?.syncIn?.selectable === true ? SyncSourceUsage.Selectable : SyncSourceUsage.NonSelectable,
  [SYNC_SOURCE_NAMES.ptp1]: config?.ptpReceiver?.instances?.[0]?.usage,
  [SYNC_SOURCE_NAMES.ptp2]: config?.ptpReceiver?.instances?.[1]?.usage,
  [SYNC_SOURCE_NAMES.osc]: undefined,
});

export const getSyncSourcePriorityConfigMap = (
  config?: TimeTransferSpec,
): Record<ValueOf<typeof SYNC_SOURCE_NAMES>, number | undefined> => ({
  [SYNC_SOURCE_NAMES.ppsIn]: config?.ppsIn?.priority,
  [SYNC_SOURCE_NAMES.gnss]: config?.gnss?.priority,
  [SYNC_SOURCE_NAMES.syncIn]: config?.syncIn?.priority,
  [SYNC_SOURCE_NAMES.osc]: config?.holdover?.priority,
  [SYNC_SOURCE_NAMES.ptp1]: config?.ptpReceiver?.instances?.[0]?.priority,
  [SYNC_SOURCE_NAMES.ptp2]: config?.ptpReceiver?.instances?.[1]?.priority,
});

export const isSyncSourceUsed = (usage?: SyncSourceUsage) =>
  usage === SyncSourceUsage.Selectable || usage === SyncSourceUsage.LocalOnly;
