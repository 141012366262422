import { A, D, pipe } from '@mobily/ts-belt';
import { isNullableOrEmpty } from '@netinsight/management-app-common-react';
import { getSyncMode } from '@netinsight/plugin-sync-inputs-ui';
import { SyncSourceUsage } from '@netinsight/syncd-schema';
import {
  useSyncRegionByNodeIdLookup,
  useSyncRegions,
  useSyncRegionStatusMetrics,
  useSyncSourceConfigs,
} from '../../hooks';
import { EXTERNAL_SYNC_SOURCES } from '@netinsight/management-app-common-api';

export const getSyncRegionStatusViewModels = ({
  syncSourceAvailability,
  syncSourceSelection,
  linkSelection,
  nodeStability,
  syncRegions,
  syncRegionByNodeIdLookup,
  syncSourceConfigs,
}: Exclude<ReturnType<typeof useSyncRegionStatusMetrics>['data'], undefined> & {
  syncSourceConfigs: Exclude<ReturnType<typeof useSyncSourceConfigs>['data'], undefined>;
} & {
  syncRegions: ReturnType<typeof useSyncRegions>['data'];
} & { syncRegionByNodeIdLookup: ReturnType<typeof useSyncRegionByNodeIdLookup> }) => {
  return Object.fromEntries(
    (syncRegions ?? []).map(sr => {
      const sourceNodeSelections = pipe(
        sr.nodeIds ?? [],
        A.map(nid => {
          const isLocalSync =
            getSyncMode({
              usageConfig: syncSourceConfigs?.[nid] ?? {},
              incomingLinkMetrics: Object.values(linkSelection?.[nid] ?? {}),
              syncSourceSelections: syncSourceSelection?.[nid] ?? {},
            }) === 'Local sync';
          return pipe(
            linkSelection?.[nid] ?? {},
            D.toPairs,
            A.filter(
              isLocalSync
                ? ([, ss]) =>
                    ss.selected === false && // when the current node is local sync, check only unused links
                    !isNullableOrEmpty(ss.clockNodeId) &&
                    syncRegionByNodeIdLookup(ss.clockNodeId) === sr.name && // only when peer node is in the same region
                    getSyncMode({
                      usageConfig: syncSourceConfigs?.[ss.clockNodeId] ?? {},
                      incomingLinkMetrics: Object.values(linkSelection?.[ss.clockNodeId] ?? {}),
                      syncSourceSelections: syncSourceSelection?.[ss.clockNodeId] ?? {},
                    }) !== 'Local sync' // and the peer node is not in Local Sync
                : ([, ss]) => !isNullableOrEmpty(ss.clockNodeId) && ss.selected, // otherwise (not local sync, enumerate peer nodes by from selected incoming links)
            ),
            A.map(([, ss]) => ss.clockNodeId as string),
          );
        }),
        A.flat,
        A.uniq,
      );

      const sourceNodeSyncRegions = pipe(
        sourceNodeSelections,
        A.map(nodeId => syncRegionByNodeIdLookup(nodeId)),
        A.uniq,
      );

      const unstableNodesCount = sr.nodeIds.filter(nid => nodeStability[nid] === false).length;
      const configuredSyncSourcesCount = sr.nodeIds.reduce(
        (count, nid) =>
          count +
          EXTERNAL_SYNC_SOURCES.reduce(
            (sum, ssn) => sum + Number(syncSourceConfigs?.[nid]?.[ssn] === SyncSourceUsage.Selectable),
            0,
          ),
        0,
      );
      const availableSyncSourcesCount = sr.nodeIds.reduce(
        (count, nid) =>
          count +
          EXTERNAL_SYNC_SOURCES.reduce(
            (sum, ssn) =>
              sum +
              Number(
                syncSourceConfigs?.[nid]?.[ssn] === SyncSourceUsage.Selectable &&
                  syncSourceAvailability?.[nid]?.[ssn] === true,
              ),
            0,
          ),
        0,
      );
      return [
        sr.id,
        {
          sourceNodeSelections,
          sourceNodeSelectionsError: sourceNodeSelections.length > 1 ? 'Sync region has multiple source nodes.' : null,
          sourceNodeSyncRegions,
          sourceNodeSyncRegionsError:
            sourceNodeSyncRegions.filter(snsr => snsr !== sr.name).length > 1
              ? 'This sync region has a sync source from another sync region.'
              : null,
          unstableNodesCount,
          unstableNodesCountError: unstableNodesCount > 0 ? 'There are unstable nodes in the region.' : null,
          syncSourcesCount: configuredSyncSourcesCount,
          availableSyncSourcesCount,
          availableSyncSourcesCountError:
            availableSyncSourcesCount < configuredSyncSourcesCount
              ? 'The number of avabile sync sources are less than the number of configured.'
              : null,
        },
      ];
    }),
  );
};
