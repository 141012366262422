import { TimeNodeManifestNodeStatusConditionType, TimeNodeLifecycleState } from '@netinsight/crds';

export function getHeartbeatStatus(
  heartbeatStatus?: TimeNodeManifestNodeStatusConditionType['status'],
  lifecycleState?: TimeNodeLifecycleState,
) {
  if (lifecycleState === 'Up' && heartbeatStatus) {
    return heartbeatStatus;
  } else if (lifecycleState !== 'Up' && typeof lifecycleState !== 'undefined') {
    return lifecycleState;
  }
  return 'Unknown';
}

export type StatusEnum = ReturnType<typeof getHeartbeatStatus>;
