import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwrMutation, { SWRMutationConfiguration } from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { TimeTransferSpec, TIMETRANSFER_DEFAULT_CONFIG } from '@netinsight/crds-timetransfer';
import { errorFromWrappedError, k8sConfigApiRef } from '../api';
import { getDuplicatedPriorities } from '../utils';
import { useSnackbarHelper } from '.';

export const useTimeTransferConfigUpdate = (
  nodeId: string,
  showNotification = true,
  overrideOpts: Partial<SWRMutationConfiguration<TimeTransferSpec, any, any>> & { throwOnError?: boolean } = {},
) => {
  const configApi = useApi(k8sConfigApiRef);
  const { snackbar } = useSnackbarHelper(!showNotification);

  const updateConfig = useCallback(
    async ([nodeIdParam]: any, { arg: updater }: { arg: (existing: TimeTransferSpec) => TimeTransferSpec }) => {
      const existingConfigResult = await configApi.getNodeTimeTransferConfig({ params: { nodeId: nodeIdParam } });

      let existingConfig: TimeTransferSpec;
      if (existingConfigResult.status === StatusCodes.OK) {
        existingConfig = { ...TIMETRANSFER_DEFAULT_CONFIG, ...existingConfigResult.body.config };
      } else if (existingConfigResult.status === StatusCodes.NOT_FOUND) {
        existingConfig = TIMETRANSFER_DEFAULT_CONFIG;
      } else {
        throw errorFromWrappedError(existingConfigResult.status, (existingConfigResult as any).body);
      }

      const updatedConfig = updater(existingConfig);

      const duplicatedPriorities = getDuplicatedPriorities(updatedConfig);
      if (duplicatedPriorities.length > 0) {
        snackbar.warning(
          `Configuration contains duplicated priorities: ${duplicatedPriorities
            .map(([priority, keys]) => `${keys.join(', ')} (${priority})`)
            .join(', ')}`,
        );
      }
      const updateResult = await configApi.setNodeTimeTransferConfig({
        params: { nodeId: nodeIdParam },
        body: updatedConfig,
      });
      if (updateResult.status !== StatusCodes.OK) {
        throw errorFromWrappedError(updateResult.status, (updateResult as any).body);
      }
      return updatedConfig;
    },
    [configApi, snackbar],
  );

  return useSwrMutation([nodeId, 'TimeTransferConfig'], updateConfig, {
    onSuccess: () => {
      snackbar.notifySuccess('Update');
    },
    onError: error => {
      snackbar.notifyError('Update', error.response, null);
    },
    populateCache: result => result,
    ...overrideOpts,
  });
};
