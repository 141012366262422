import { scaleOrdinal } from 'd3-scale';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ButtonGroupProps,
  ButtonProps,
  useTheme,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { A, F } from '@mobily/ts-belt';

export type SplitButtonProps = {
  options?: [string, any][];
  initialSelectedOption?: any;
  onOptionSelected?: (selectedValue: any) => void;
  onStateSelected?: (selectedValue: any) => void;
  buttonTextFormatter?: (selectedValue: any, selectedLabel: string) => string;
  buttonProps?: Omit<ButtonProps, 'onClick' | 'color' | 'aria-controls' | 'aria-haspopup' | 'aria-expanded'>;
} & Omit<ButtonGroupProps, 'ref'>;

export const SplitButton: FunctionComponent<SplitButtonProps> = ({
  initialSelectedOption,
  color,
  variant,
  buttonProps,
  buttonTextFormatter = F.identity,
  onOptionSelected = F.ignore,
  onStateSelected = F.ignore,
  options = [],
  ...buttonGroupProps
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => {
    onOptionSelected(selectedOption);
  }, [selectedOption, onOptionSelected]);
  const handleMenuItemClick = useCallback(
    (_: any, option: any) => {
      setSelectedOption(option);
      onStateSelected(option);
      setOpen(false);
    },
    [onStateSelected],
  );
  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, []);
  const handleClose = useCallback((event: any) => {
    if (anchorRef.current?.contains(event.target) ?? false) {
      return;
    }
    setOpen(false);
  }, []);

  const [labels, values] = A.unzip(options);
  const reverseLookup = scaleOrdinal(values, labels).unknown('');
  const theme = useTheme();

  return (
    <>
      <ButtonGroup variant={variant} color={color} ref={anchorRef} aria-label="Split button" {...buttonGroupProps}>
        <Button
          type="button"
          onClick={handleClick}
          data-testid={`btn-selected-${reverseLookup(selectedOption).toLowerCase()}`}
          {...buttonProps}
        >
          {buttonTextFormatter(selectedOption, reverseLookup(selectedOption))}
        </Button>
        <Button
          color={color}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          data-testid="btn-popdown"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        style={{ zIndex: theme.zIndex.modal }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top right',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" variant="menu">
                  {options.map(([label, value]) => (
                    <MenuItem
                      key={value}
                      selected={value === selectedOption}
                      onClick={event => handleMenuItemClick(event, value)}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
