import StatusCodes from 'http-status-codes';
import useSwr, { SWRConfiguration } from 'swr';
import { useApi } from '@backstage/core-plugin-api';
import { D, pipe } from '@mobily/ts-belt';
import { groupMetricsFromPromiseBy2Levels } from '@netinsight/management-app-common-api';
import { errorFromWrappedError, k8sTTLinksApiRef, metricsApiRef } from '@netinsight/management-app-common-react';
import { linkDirectionMetaMapper } from '../utils';

export const NodeLinksByDirectionCacheKey = 'useNodeLinksByDirection';

export const useNodeLinksByDirection = (nodeId: string, swrConfig?: SWRConfiguration) => {
  const metricsApi = useApi(metricsApiRef);
  const ttLinksApi = useApi(k8sTTLinksApiRef);
  return useSwr(
    [nodeId, NodeLinksByDirectionCacheKey],
    async ([nodeIdParam]) => {
      const ttLinkResponse = await ttLinksApi.getNodeLinkTargets({ params: { nodeId: nodeIdParam } });
      if (ttLinkResponse.status !== StatusCodes.OK) {
        throw errorFromWrappedError(ttLinkResponse.status, ttLinkResponse.body);
      }
      const linkTargets = ttLinkResponse.body?.config?.targets ?? [];
      const linkIds = linkTargets?.map(lt => lt.linkId) ?? [];
      const nodeIds = [...new Set<string>((linkTargets?.map(lt => lt.node) ?? []).concat([nodeId])).values()];
      const linkIdsQuery = linkIds.join('|');
      const [linkSelectedResult, selectedProfileIndexResult] = await Promise.allSettled([
        metricsApi.instantQuery({
          query: `last_over_time(neti_hicc_syncsource_is_selected{type="LINK", name=~"${linkIdsQuery}"}[30s])`,
        }),
        metricsApi.instantQuery({
          query: `last_over_time(neti_hicc_multilink_selected_profile{link_id=~"${linkIdsQuery}"}[30s])`,
        }),
      ]);

      const selectedProfileIndices = groupMetricsFromPromiseBy2Levels(
        selectedProfileIndexResult,
        m => m.link_id,
        m => m.nodeid,
        val => parseInt(val, 10),
      );

      const linkDirectionMap = groupMetricsFromPromiseBy2Levels(
        linkSelectedResult,
        metric => metric.name,
        metric => metric.nodeid,
        linkDirectionMetaMapper,
      );
      const outLinks = linkTargets.filter(({ linkId }) =>
        pipe(
          linkDirectionMap[linkId] ?? {},
          D.filterWithKey((linkNodeId, { selected }) => linkNodeId !== nodeIdParam && selected),
          D.isNotEmpty,
        ),
      );
      const inLinks = linkTargets.filter(({ linkId }) =>
        pipe(
          linkDirectionMap[linkId] ?? {},
          D.filterWithKey((linkNodeId, { selected }) => linkNodeId === nodeIdParam && selected),
          D.isNotEmpty,
        ),
      );

      const usedLinkIds = new Set([...outLinks.map(l => l.linkId), ...inLinks.map(l => l.linkId)]);

      const unusedLinks = linkTargets.filter(({ linkId }) => !usedLinkIds.has(linkId));

      return { inLinks, outLinks, unusedLinks, linkDirectionMap, selectedProfileIndices, nodeIds, linkIds };
    },
    swrConfig,
  );
};
