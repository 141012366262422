import StatusCodes from 'http-status-codes';
import { useCallback } from 'react';
import useSwr from 'swr';
import useSwrMutation from 'swr/mutation';
import { useApi } from '@backstage/core-plugin-api';
import { GlobalLinkOptions } from '@netinsight/crds-timetransfer';
import {
  errorFromWrappedError,
  isNotNullableButEmpty,
  k8sConfigApiRef,
  useSnackbarHelper,
} from '@netinsight/management-app-common-react';

const CacheKey = 'GlobalLinkOptions';

export const useGlobalLinkOptionsUpdate = () => {
  const configApi = useApi(k8sConfigApiRef);

  const { snackbar } = useSnackbarHelper();

  const update = async (_url: string, { arg: newOptions }: { arg: GlobalLinkOptions }) => {
    // avoid an error that occurs when the object is not null but empty
    if (isNotNullableButEmpty(newOptions?.dynamicLinkChangeThresholdParams?.bad)) {
      delete newOptions!.dynamicLinkChangeThresholdParams!.bad;
    }
    if (isNotNullableButEmpty(newOptions?.dynamicLinkChangeThresholdParams?.good)) {
      delete newOptions!.dynamicLinkChangeThresholdParams!.good;
    }
    if (isNotNullableButEmpty(newOptions?.dynamicLinkChangeThresholdParams)) {
      delete newOptions!.dynamicLinkChangeThresholdParams;
    }
    const response = await configApi.setGlobalLinkOptions({ body: newOptions });

    if (response.status !== StatusCodes.OK) {
      throw errorFromWrappedError(response.status, response.body);
    }
  };

  return useSwrMutation(CacheKey, update, {
    throwOnError: false, // handling error in onError
    onSuccess: () => {
      snackbar.notifySuccess('Updated global link options');
    },
    onError: error => {
      snackbar.notifyError('Update', error.response, null);
    },
  });
};

export function useGlobalLinkOptions() {
  const configApi = useApi(k8sConfigApiRef);

  const fetcher = useCallback(async () => {
    const result = await configApi.getGlobalLinkOptions();
    if (result.status !== StatusCodes.OK) {
      throw errorFromWrappedError(result.status, (result as any).body);
    }

    return result.body;
  }, [configApi]);

  return useSwr(CacheKey, fetcher);
}
