import React from 'react';
import { InfoCard, Progress } from '@backstage/core-components';
import { useNavigate } from 'react-router';
import { Button, Checkbox } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {
  errorFromWrappedError,
  k8sNodeApiRef,
  useSnackbarHelper,
  useTimeNode,
} from '@netinsight/management-app-common-react';
import { useApi } from '@backstage/core-plugin-api';
import StatusCodes from 'http-status-codes';

export const TimeNodeRemoval = ({ nodeId }: { nodeId: string }) => {
  const nodeApi = useApi(k8sNodeApiRef);
  const navigate = useNavigate();
  const { snackbar } = useSnackbarHelper();

  const [open, setOpen] = React.useState<boolean>(false);
  const [openForce, setOpenForce] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [retainNetwork, setRetainNetwork] = React.useState<boolean>(true);
  const openDialog = () => setOpen(true);
  const closeDialog = () => {
    if (deleting) return;
    setOpen(false);
  };
  const openForceDialog = () => setOpenForce(true);
  const closeForceDialog = () => {
    if (deleting) return;
    setOpenForce(false);
  };
  const retainNetworkOnChange = () => {
    setRetainNetwork(!retainNetwork);
  };

  const { data: nodeState, isLoading } = useTimeNode(nodeId, { refreshInterval: 15_000 });

  if (isLoading) {
    return (
      <InfoCard title="Offboarding" subheader="Remove this node from the network">
        <Progress />
      </InfoCard>
    );
  }

  const nodeName = nodeState?.spec.name ?? nodeId;
  const deleteNodeImpl = (force: boolean) => {
    setDeleting(true);
    nodeApi
      .deleteNode({
        params: { nodeId },
        query: { force: force ? 'true' : undefined, retainNetwork: retainNetwork ? 'true' : undefined },
      })
      .then(result => {
        if (result.status !== StatusCodes.OK) {
          throw errorFromWrappedError(result.status, result.body);
        }
        snackbar.success(`Node '${nodeName}' is being deleted`);
        if (force) {
          closeForceDialog();
        } else {
          closeDialog();
        }
        navigate('/nodes');
      })
      .catch(err => snackbar.error(`Delete of node '${nodeName}' error: ${err.message}`))
      .finally(() => setDeleting(false));
  };
  const ForceRemovalTimeout = 60000;
  const deleteNode = () => deleteNodeImpl(false);
  const forceDeleteNode = () => deleteNodeImpl(true);
  const isOffboarding = Boolean(nodeState?.deletionTimestamp);
  const subheader = isOffboarding
    ? 'Node is being reset and removed from the network'
    : 'Remove this node from the network';
  const timedOut = () =>
    nodeState && nodeState.deletionTimestamp
      ? Date.parse(nodeState.deletionTimestamp) + ForceRemovalTimeout < Date.now()
      : false;
  const namedState = nodeState?.spec.lifecycleState;
  const offboardableState = namedState && ['Up', 'Onboarding', 'WaitForActivation'].includes(namedState);
  const forceDelete = isOffboarding ? timedOut() : !offboardableState;
  const disableButton = forceDelete ? deleting : deleting || isOffboarding || !offboardableState;

  return (
    <InfoCard title="Offboarding" subheader={subheader}>
      <Button
        disabled={disableButton}
        variant="contained"
        size="large"
        color="secondary"
        onClick={forceDelete ? openForceDialog : openDialog}
        startIcon={forceDelete ? <RemoveCircleIcon /> : <DeleteForeverIcon />}
        data-testid="btn-begin-remove-node"
      >
        {forceDelete ? 'Force Delete' : 'Delete'}
      </Button>
      <Dialog open={open} onClose={closeDialog} data-testid="dialog-delete-node">
        <DialogTitle>Delete {nodeName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The node will be removed from the network. If the node is online, it will be factory reset. Are you sure you
            want to delete the node '{nodeName}'?
          </DialogContentText>
          <DialogContentText>
            <Checkbox onChange={retainNetworkOnChange} checked={retainNetwork} /> Retain network configuration
          </DialogContentText>
          {deleting && <Progress />}
        </DialogContent>
        <DialogActions>
          <Button disabled={deleting} onClick={closeDialog} data-testid="btn-cancel-remove-node">
            Cancel
          </Button>
          <Button
            disabled={deleting}
            variant="contained"
            color="secondary"
            onClick={deleteNode}
            data-testid="btn-confirm-remove-node"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openForce} onClose={closeForceDialog} data-testid="dialog-force-delete-node">
        <DialogTitle>Force delete {nodeName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Node '{nodeName}' is {namedState}. Factory reset for this node may not be triggered. In that case, the node
            will need to be manually reset before it is reused. Offboarding of the node may be incomplete. Do you want
            to force removal of the node from the cluster?
          </DialogContentText>
          {deleting && <Progress />}
        </DialogContent>
        <DialogActions>
          <Button disabled={deleting} onClick={closeForceDialog} data-testid="btn-cancel-remove-node">
            Cancel
          </Button>
          <Button
            disabled={deleting}
            variant="contained"
            color="secondary"
            onClick={forceDeleteNode}
            data-testid="btn-confirm-remove-node"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </InfoCard>
  );
};
