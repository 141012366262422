import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { Link, Table, TableColumn } from '@backstage/core-components';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import { NetiColors } from '../../constants';
import { notUndefinedOrNull } from '../../utils';
import { useTableState } from '../../hooks';
import { AlertMetadata } from '@netinsight/management-app-common-react';
import { ProfileInfo } from '@backstage/core-plugin-api';
import { ALARM_SEVERITY_ORDER, compareSeverity } from '@netinsight/management-app-common-api';

const useStyles = makeStyles(theme => ({
  table: {},
  colorBand: {
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: '100%',
    width: '1rem',
    ['&[data-severity="critical"]']: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.error.dark : theme.palette.error.main,
    },
    ['&[data-severity="major"]']: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.warning.dark : theme.palette.warning.main,
    },
    ['&[data-severity="minor"]']: {
      backgroundColor: theme.palette.type === 'dark' ? NetiColors.yellow.dark : NetiColors.yellow.main,
    },
    ['&[data-severity="warning"]']: {
      backgroundColor: theme.palette.type === 'dark' ? theme.palette.info.dark : theme.palette.info.main,
    },
  },
}));

const cellStyle = {
  padding: '0.625rem 0.75rem',
};

export interface AlertInfo {
  group: string;
  details: string;
  severity: string;
  node: string;
  object: string;
  nodeName: string;
  syncRegion: string;
  raised: string | null;
  fingerprint: string;
  metadata?: AlertMetadata;
  acknowledged: boolean;
}

export function AlarmDatetime(props: { date: string | null }) {
  if (!props.date) return null;

  return (
    <time dateTime={props.date} title={DateTime.fromISO(props.date).toRelative()!}>
      {DateTime.fromISO(props.date).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}
    </time>
  );
}

function AcknowledgeLabel(props: { metadata?: AlertMetadata }) {
  if (!props.metadata?.updatedBy) return null;
  const text = `Acknowledged by ${props.metadata?.updatedBy}`;
  const date = props.metadata?.updatedAt ? <AlarmDatetime date={props.metadata?.updatedAt} /> : null;

  return (
    <Typography variant="caption" color="textSecondary">
      {text} {date ? 'at' : null} {date}
    </Typography>
  );
}

const searchAlertMetadata = (term: string, metadata?: AlertMetadata) => {
  const { updatedBy, acknowledged } = metadata ?? { acknowledged: false, updatedBy: undefined };
  const termToSearch = term.toLowerCase();
  const acknowledgementText = acknowledged ? 'acknowledged' : 'unacknowledged';
  // to differentiate between acknowledgement and unacknowledgement use startsWith rather than includes
  if (acknowledgementText.startsWith(termToSearch)) return true;

  const searchItemsRest = [updatedBy].filter(notUndefinedOrNull).map(str => str.toLowerCase());
  for (const item of searchItemsRest) {
    if (item.includes(termToSearch)) return true;
  }

  return false;
};

const genColumns = (updateAlertMetadata: (metadataList: AlertMetadata[]) => void, profileInfo?: ProfileInfo) => {
  const columns: TableColumn<AlertInfo>[] = [
    {
      title: 'Node',
      field: 'nodeName',
      width: '18rem',
      render: data => (
        <Typography variant="body2" component={Link} to={`/nodes/info/${data.node}`}>
          {data.nodeName}
        </Typography>
      ),
      cellStyle,
    },
    {
      title: 'Object',
      width: '12rem',
      field: 'object',
      cellStyle,
    },
    {
      title: 'Region',
      width: '8rem',
      field: 'syncRegion',
      filtering: false,
      cellStyle,
    },
    {
      title: 'Severity',
      field: 'severity',
      width: '6rem',
      defaultSort: 'asc',
      customSort: (data1, data2) => compareSeverity(data1.severity, data2.severity),
      lookup: Object.fromEntries(ALARM_SEVERITY_ORDER.map(severity => [severity, severity])),
      cellStyle,
    },
    {
      title: 'Type',
      width: '10rem',
      field: 'type',
      cellStyle,
    },
    {
      title: 'Summary',
      width: '12rem',
      field: 'group',
      cellStyle,
    },
    {
      title: 'Details',
      field: 'details',
      width: 'auto',
      highlight: false,
      cellStyle,
    },
    {
      title: 'Raised',
      field: 'raised',
      width: '6.5rem',
      filtering: false,
      render: row => <AlarmDatetime date={row.raised} />,
      cellStyle,
    },
    {
      title: 'Acknowledgement',
      field: 'acknowledged',
      width: '8rem',
      lookup: { true: 'Acknowledged', false: 'Unacknowledged' },
      render: row => {
        const buttonText = row.acknowledged ? 'Unacknowledge' : 'Acknowledge';
        const buttonColor = row.acknowledged ? 'secondary' : 'primary';
        return (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Button
              size="small"
              fullWidth
              color={buttonColor}
              onClick={() => {
                const newAcknowledged = !row.acknowledged;
                const newUpdatedBy = newAcknowledged ? (profileInfo?.email ?? profileInfo?.displayName) : undefined;

                updateAlertMetadata([
                  {
                    labels: { fingerprint: row.fingerprint!, startsAt: row.raised! },
                    acknowledged: newAcknowledged,
                    updatedBy: newUpdatedBy,
                  },
                ]);
              }}
              variant="contained"
            >
              {buttonText}
            </Button>
            <Box mt={1} textAlign="center" display="flex" flexDirection="column">
              <AcknowledgeLabel metadata={row.metadata} />
            </Box>
          </Box>
        );
      },
    },
    // Hidden columns just for filtering
    {
      title: 'Fingerprint',
      field: 'fingerprint',
      hidden: true,
      searchable: true,
    },
    {
      title: 'Metadata',
      field: 'metadata',
      hidden: true,
      searchable: true,
      customFilterAndSearch: (term, row) => searchAlertMetadata(term, row.metadata),
    },
  ];
  return columns;
};

export const AlarmsTable: FunctionComponent<{
  alerts: AlertInfo[];
  tableFilter?: ReactNode;
  nodeId?: string;
  metadataUpdate: (metadataList: AlertMetadata[]) => void;
  profileInfo?: ProfileInfo;
  stateStorageKey?: string;
}> = ({ alerts, tableFilter, nodeId, metadataUpdate, profileInfo, stateStorageKey }) => {
  const styles = useStyles();

  const columns = useMemo(() => genColumns(metadataUpdate, profileInfo), [metadataUpdate, profileInfo]);

  const colorBandColumn = useMemo<TableColumn<AlertInfo>>(
    () => ({
      cellStyle: {
        padding: 0,
        width: '0.125rem',
        height: '100%',
        position: 'relative',
      },
      width: '0rem',
      filtering: false,
      sorting: false,
      render: row => <span className={styles.colorBand} data-severity={row.severity} aria-hidden />,
    }),
    [styles],
  );

  const tableColumns = useMemo(
    () => [colorBandColumn, ...(nodeId ? columns.slice(1) : columns)],
    [colorBandColumn, nodeId, columns],
  );

  const tableOptions = useMemo(
    () => ({
      search: false,
      filtering: true,
      grouping: false,
      pageSize: 25,
      thirdSortClick: false,
      pageSizeOptions: [25, 50, 100],
      emptyRowsWhenPaging: false,
      padding: 'dense' as const,
      headerStyle: {
        textTransform: 'uppercase' as const,
        padding: cellStyle.padding,
        textAlign: 'left' as const,
      },
    }),
    [],
  );

  const tableStates = useTableState(tableColumns, tableOptions, stateStorageKey ?? 'alarms-table');

  return (
    <>
      {tableFilter}
      <Table title="Alarms" data={alerts} {...tableStates} />
    </>
  );
};
