import React from 'react';
import { InfoCard } from '@backstage/core-components';
import { Button, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { useUserProfile } from './useUserProfileInfo';
import { errorApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';

export const ProfileCard = () => {
  const errorApi = useApi(errorApiRef);
  const identityApi = useApi(identityApiRef);
  const { profile, displayName, backstageIdentity } = useUserProfile();

  return (
    <InfoCard title="Profile" variant="gridItem">
      <Grid item xs={12} sm container>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Typography variant="subtitle1" gutterBottom>
              {displayName}
            </Typography>
            {profile.email && (
              <Typography variant="body2" color="textSecondary">
                {profile.email}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => identityApi.signOut().catch(error => errorApi.post(error))}
          >
            Sign Out
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Permissions</Typography>
          <List dense>
            {backstageIdentity?.ownershipEntityRefs.sort().map(entity => (
              <ListItem key={entity} button>
                <ListItemText primary={entity} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
