import { Checkbox, ListItemText, MenuItem, Select, SelectProps, Typography, makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { F, G } from '@mobily/ts-belt';
import { compareSyncRegion, TimeNodeSyncRegionWithNodeIds } from '@netinsight/management-app-common-api';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
  },
  label: {
    fontWeight: 'bold',
  },
}));

export const useSyncRegionsSelectOptions = ({
  values = [],
  onValuesChange = F.ignore,
  syncRegions = [],
}: {
  values?: string[];
  onValuesChange: (region: string[]) => void;
  syncRegions?: TimeNodeSyncRegionWithNodeIds[];
}) => {
  const allRegionNodeCount = useMemo(
    () => syncRegions.map(x => x.nodeIds?.length ?? 0).reduce((sum, num) => sum + num, 0),
    [syncRegions],
  );
  const options = useMemo(() => {
    return [
      <MenuItem value="" key="_all">
        <Checkbox value="" checked={values.length === 0 || values.length === syncRegions.length} color="primary" />
        <ListItemText primary="All" secondary={`${allRegionNodeCount} nodes`} />
      </MenuItem>,
    ].concat(
      syncRegions.map(({ name: regionName, nodeIds: regionNodeIds = [] }) => (
        <MenuItem key={regionName} value={regionName}>
          <Checkbox value={regionName} checked={values.indexOf(regionName) > -1} color="primary" />
          <ListItemText
            primary={regionName}
            secondary={`${regionNodeIds.length} node${regionNodeIds.length === 1 ? '' : 's'}`}
          />
        </MenuItem>
      )),
    );
  }, [values, syncRegions, allRegionNodeCount]);

  const onChange: SelectProps['onChange'] = useCallback(
    (evt: any) => {
      const selectedVals = evt.target?.value;
      if (G.isArray(selectedVals)) {
        onValuesChange(selectedVals.indexOf('') > -1 ? [] : evt.target.value.filter(Boolean));
      }
    },
    [onValuesChange],
  );

  return { options, onChange };
};

export const SyncRegionSelector: FunctionComponent<{
  currentValue?: string[];
  onChange: (region: string[]) => void;
  syncRegions: TimeNodeSyncRegionWithNodeIds[];
  readonly?: boolean;
}> = ({ syncRegions, onChange, currentValue = [], readonly }) => {
  const { options: regionOptions, onChange: handleChange } = useSyncRegionsSelectOptions({
    values: currentValue,
    onValuesChange: onChange,
    syncRegions,
  });

  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Typography variant="body1" component="label" id="region-selector-label" className={styles.label}>
        Regions
      </Typography>
      <Select
        multiple
        labelId="region-selector-label"
        id="region-selector"
        displayEmpty
        placeholder="All"
        onChange={handleChange}
        renderValue={(selectedValues: any = []) =>
          selectedValues.length === 0 ? 'All' : selectedValues.sort(compareSyncRegion).join(', ')
        }
        value={currentValue}
        readOnly={readonly}
      >
        {regionOptions}
      </Select>
    </div>
  );
};
