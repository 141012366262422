import { A, D, F, flow, G, S } from '@mobily/ts-belt';
import {
  ZodArray,
  ZodDefault,
  ZodLiteral,
  ZodNativeEnum,
  ZodNumber,
  ZodObject,
  ZodOptional,
  ZodString,
  ZodTypeAny,
  ZodUnion,
} from 'zod';

export const isZodOptional = (input: ZodTypeAny): input is ZodOptional<any> =>
  input.isOptional() && typeof (input as ZodOptional<any>).unwrap === 'function';

export const isZodDefault = (input: ZodTypeAny): input is ZodDefault<any> =>
  typeof (input as ZodDefault<any>).removeDefault === 'function';

export const isZodString = (input: ZodTypeAny): input is ZodString => typeof (input as ZodString).regex === 'function';

export const isZodNumber = (input: ZodTypeAny): input is ZodNumber => typeof (input as ZodNumber).int === 'function';

export const isZodUnion = <
  TItemTypeName extends 'string' | 'number',
  TZodItemType extends string | number = TItemTypeName extends 'string' ? string : number,
>(
  input: ZodTypeAny,
  itemType: TItemTypeName,
): input is ZodUnion<[ZodLiteral<TZodItemType>, ...ZodLiteral<TZodItemType>[]]> => {
  const options = (input as ZodUnion<any>).options;
  return (
    options !== null &&
    options !== undefined &&
    options.length &&
    (options as ZodLiteral<any>[]).every(option => typeof option.value === itemType)
  );
};

export const isZodNativeEnum = <TItemTypeName extends 'string' | 'number'>(
  input: ZodTypeAny,
  itemType: TItemTypeName,
): input is ZodNativeEnum<any> => {
  const enumObj = (input as ZodNativeEnum<any>).enum;
  if (typeof enumObj !== 'object') {
    return false;
  }
  const enumVals = Object.values(enumObj);

  return itemType === 'string'
    ? enumVals.every(val => typeof val === 'string')
    : enumVals.some(val => typeof val === 'number');
};

export const isZodObject = (input: ZodTypeAny): input is ZodObject<any> => 'shape' in input;
export const isZodArray = (input: ZodTypeAny): input is ZodArray<any> => 'element' in input;
export const isNullableOrEmpty = (input?: string | null): input is null | undefined | '' =>
  G.isNullable(input) || (G.isString(input) && S.isEmpty(input));

export const isNotNullableButEmpty = F.both<any>(
  G.isNotNullable,
  F.either(D.isEmpty, flow(D.values, A.every(G.isNullable))),
);
