import { OAuth2 } from '@backstage/core-app-api';
import {
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  OAuthApi,
  oauthRequestApiRef,
  OpenIdConnectApi,
  ProfileInfoApi,
  SessionApi,
} from '@backstage/core-plugin-api';
import { apis as commonApis } from '@netinsight/management-app-common-react';

export const idpAuthApiRef: ApiRef<OAuthApi & OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi> =
  createApiRef({
    id: 'internal.auth.idp',
  });

export const idpReadonlyAuthApiRef: ApiRef<
  OAuthApi & OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
  id: 'internal.auth.idp-readonly',
});

export const localAuthApiRef: ApiRef<OAuthApi & OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi> =
  createApiRef({
    id: 'internal.auth.local',
  });

const idpAuthApi = createApiFactory({
  api: idpAuthApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    oauthRequestApi: oauthRequestApiRef,
    configApi: configApiRef,
  },
  factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
    OAuth2.create({
      discoveryApi,
      oauthRequestApi,
      configApi,
      provider: {
        id: 'idp',
        title: 'OIDC',
        icon: () => null,
      },
      environment: configApi.getOptionalString('auth.environment'),
    }),
});

const idpReadonlyAuthApi = createApiFactory({
  api: idpReadonlyAuthApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    oauthRequestApi: oauthRequestApiRef,
    configApi: configApiRef,
  },
  factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
    OAuth2.create({
      discoveryApi,
      oauthRequestApi,
      configApi,
      provider: {
        id: 'idp-readonly',
        title: 'OIDC',
        icon: () => null,
      },
      environment: configApi.getOptionalString('auth.environment'),
    }),
});

const localAuthApi = createApiFactory({
  api: localAuthApiRef,
  deps: {
    discoveryApi: discoveryApiRef,
    oauthRequestApi: oauthRequestApiRef,
    configApi: configApiRef,
  },
  factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
    OAuth2.create({
      discoveryApi,
      oauthRequestApi,
      configApi,
      provider: {
        id: 'local',
        title: 'Local',
        icon: () => null,
      },
      environment: configApi.getOptionalString('auth.environment'),
    }),
});

export const authApiRefs = [idpAuthApiRef, idpReadonlyAuthApiRef, localAuthApiRef];

export const apis: AnyApiFactory[] = [...commonApis, idpAuthApi, idpReadonlyAuthApi, localAuthApi];
