import classNames from 'classnames';
import React, { FunctionComponent, useMemo } from 'react';
import { InfoCard, Link, ResponseErrorPanel } from '@backstage/core-components';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { useNodeNameMap, useTableStyles } from '@netinsight/management-app-common-react';
import {
  useSyncRegionByNodeIdLookup,
  useSyncRegions,
  useSyncRegionStatusMetrics,
  useSyncSourceConfigs,
} from '../../hooks';
import { getSyncRegionStatusViewModels } from './helper';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  listIcon: {
    fill: theme.palette.success.main,
  },
  infocardContentContainer: {
    padding: 0,
  },
}));

const CellError: FunctionComponent<{ error: string | null }> = ({ error }) => {
  return error ? <div className="cell-error" title={error} /> : null;
};

export const SyncRegionStatus: FunctionComponent = () => {
  const { data: syncRegions, isLoading, error } = useSyncRegions();
  const syncRegionByNodeIdLookup = useSyncRegionByNodeIdLookup(syncRegions);
  const { data: nodeNameMap = {} } = useNodeNameMap();
  const {
    data: { syncSourceAvailability = {}, syncSourceSelection = {}, linkSelection = {}, nodeStability = {} } = {},
  } = useSyncRegionStatusMetrics({ refreshInterval: 15_000 });
  const { data: syncSourceConfigs = {} } = useSyncSourceConfigs();

  const viewModels = useMemo(
    () =>
      getSyncRegionStatusViewModels({
        syncSourceAvailability,
        syncSourceSelection,
        linkSelection,
        nodeStability,
        syncSourceConfigs,
        syncRegions,
        syncRegionByNodeIdLookup,
      }),
    [
      syncRegions,
      syncSourceAvailability,
      syncSourceSelection,
      linkSelection,
      nodeStability,
      syncSourceConfigs,
      syncRegionByNodeIdLookup,
    ],
  );
  const tableStyles = useTableStyles();
  const styles = useStyles();

  if (isLoading) {
    return <LinearProgress />;
  }
  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <InfoCard title="Sync regions overview" cardClassName={styles.infocardContentContainer}>
      <table className={tableStyles.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Selected network sync source nodes</th>
            <th>Source node regions</th>
            <th>Configured sync sources</th>
            <th>Available sync sources</th>
            <th>Nodes</th>
            <th>Unstable nodes</th>
          </tr>
        </thead>
        <tbody>
          {syncRegions?.map(sr => (
            <tr key={sr.id}>
              <th>{sr.name}</th>
              <td
                className={classNames(['value', { 'error-container': viewModels[sr.id]?.sourceNodeSelectionsError }])}
              >
                <ul>
                  {viewModels[sr.id]?.sourceNodeSelections?.map((nodeId, index) => (
                    <li key={index} className={styles.listItem}>
                      <Link to={`/nodes/info/${nodeId}`} target="_blank">
                        {nodeNameMap[nodeId] ?? nodeId}
                      </Link>
                    </li>
                  ))}
                </ul>
                <CellError error={viewModels[sr.id]?.sourceNodeSelectionsError} />
              </td>
              <td
                className={classNames(['value', { 'error-container': viewModels[sr.id]?.sourceNodeSelectionsError }])}
              >
                <ul>
                  {viewModels[sr.id]?.sourceNodeSyncRegions?.map((syncRegion, index) => (
                    <li key={index} className={styles.listItem}>
                      {syncRegion}
                    </li>
                  ))}
                </ul>
                <CellError error={viewModels[sr.id]?.sourceNodeSyncRegionsError} />
              </td>
              <td className="value numeric">{viewModels[sr.id]?.syncSourcesCount}</td>
              <td
                className={classNames([
                  'value',
                  'numeric',
                  {
                    'error-container': viewModels[sr.id].availableSyncSourcesCountError,
                  },
                ])}
              >
                {viewModels[sr.id]?.availableSyncSourcesCount}
                <CellError error={viewModels[sr.id]?.availableSyncSourcesCountError} />
              </td>
              <td className="value numeric">{sr.nodeIds.length}</td>
              <td
                className={classNames([
                  'value',
                  'numeric',
                  { 'error-container': viewModels[sr.id]?.unstableNodesCountError },
                ])}
              >
                {viewModels[sr.id]?.unstableNodesCount}
                <CellError error={viewModels[sr.id]?.unstableNodesCountError} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </InfoCard>
  );
};
