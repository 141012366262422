import type { TypographyProps } from '@material-ui/core';

export const FeatureFlags = {
  ShowAdvancedSettings: 'show-advanced-settings',
  ShowExperimentalFeatures: 'show-experimental-features',
  EnableDebugMode: 'enable-debug-mode',
} as const;

export const InventoryKinds = {
  SyncStatus: 'syncd-syncstatus',
  GNSS: 'gnssctld',
  NodeManager: 'node-manager',
  SyncdProfileDelete: 'syncd-profiledelete',
  SyncdProfileRequest: 'syncd-profilerequest',
  NodeGraphState: 'node-graph-state',
} as const;

export const NetiColors = {
  blue: {
    light: '#B2CCFF',
    main: '#0055FF',
    dark: '#001EAA',
  },
  green: {
    light: '#B6F8EC',
    main: '#0AE6BE',
    dark: '#0A6E78',
  },
  red: {
    light: '#FDC5CE',
    main: '#FA3C5A',
    dark: '#96281E',
  },
  yellow: {
    light: '#FFF3B2',
    main: '#FFD800',
    dark: '#DC8C28',
  },
  orange: {
    light: '#FDA455',
    main: '#FD8E2B',
    dark: '#DB6802',
  },
};

export type HeadingTag = `h${1 | 2 | 3 | 4 | 5 | 6}`;

export const NetiHeadingTypographyProps: Record<HeadingTag, TypographyProps & { component: HeadingTag }> = {
  h1: { component: 'h1', variant: 'h1' },
  h2: { component: 'h2', variant: 'h4' },
  h3: { component: 'h3', variant: 'h5' },
  h4: { component: 'h4', variant: 'h6' },
  h5: { component: 'h5', variant: 'h6' },
  h6: { component: 'h6', variant: 'h6' },
};
