import { useMemo } from 'react';
import { groupMetricsVector } from '@netinsight/management-app-common-api';
import { useInstantMetrics } from '@netinsight/management-app-common-react';
import { SWRConfiguration } from 'swr';

export const useLinkSelectedProfileIndices = (linkId: string, options?: SWRConfiguration) => {
  const {
    metrics: selectedProfileIndexMetrics,
    loading,
    error,
  } = useInstantMetrics({
    query: `neti_hicc_multilink_selected_profile{link_id="${linkId}"}`,
    options,
  });
  const selectedProfileIndices = useMemo(
    () =>
      groupMetricsVector(
        selectedProfileIndexMetrics,
        metric => metric.nodeid,
        val => parseInt(val, 10),
      ),
    [selectedProfileIndexMetrics],
  );
  return { data: selectedProfileIndices, isLoading: loading, error };
};
