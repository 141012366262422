import React, { FunctionComponent } from 'react';
import { StatusCondition } from '@netinsight/crds-common';
import { TimeNodeLifecycleState, TimeNodeManifestNodeStatusConditionType } from '@netinsight/crds';
import OfflineIcon from '@material-ui/icons/CloudOff';
import OnlineIcon from '@material-ui/icons/Cloud';
import UnknownIcon from '@material-ui/icons/CloudQueue';
import UpgradeIcon from '@material-ui/icons/CloudUpload';
import RebootIcon from '@material-ui/icons/Loop';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Chip, makeStyles, SvgIconTypeMap } from '@material-ui/core';
import { DateTime } from 'luxon';
import { getHeartbeatStatus, StatusEnum } from '@netinsight/management-app-common-api';

const NEVER = new Date(0).toISOString();

export type NodeOnlineStatusIconProps = {
  lifecycleState?: TimeNodeLifecycleState;
  heartbeatStatus?: TimeNodeManifestNodeStatusConditionType['status'];
  heartbeatTime?: string;
  iconOnly?: boolean;
  offlineOnly?: boolean;
};

const Icons: Record<StatusEnum, OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>> = {
  True: OnlineIcon,
  False: OfflineIcon,
  Unknown: UnknownIcon,
  Offboarding: OfflineIcon,
  Onboarding: OnlineIcon,
  Upgrading: UpgradeIcon,
  WaitForActivation: UpgradeIcon,
  Activating: RebootIcon,
  Rebooting: RebootIcon,
  Rollback: UpgradeIcon,
};

export const HeartbeatStatusLabels: Record<StatusEnum, string> = {
  True: 'Online',
  False: 'Offline',
  Unknown: 'Unknown status',
  Offboarding: 'Offboarding',
  Onboarding: 'Onboarding',
  Upgrading: 'Upgrading',
  WaitForActivation: 'Upgrade waiting to be activated',
  Activating: 'Activating installed release',
  Rebooting: 'Rebooting',
  Rollback: 'Rollback',
};

const useStyles = makeStyles(theme => ({
  offlineIcon: {
    fill: theme.palette.error.main,
  },
  onlineIcon: {
    fill: theme.palette.success.main,
  },
  unknownIcon: {
    fill: theme.palette.grey[500],
  },
  offboardingIcon: {
    fill: theme.palette.grey[400],
  },
  onboardingIcon: {
    fill: theme.palette.grey[300],
  },
  upgradingIcon: {
    fill: theme.palette.info.main,
  },
  rebootingIcon: {
    fill: theme.palette.warning.main,
  },
  chip: {
    margin: theme.spacing(1, 0, 0),
  },
}));

export const NodeOnlineStatusIcon: FunctionComponent<NodeOnlineStatusIconProps> = ({
  lifecycleState,
  heartbeatStatus,
  heartbeatTime,
  iconOnly = false,
  offlineOnly = false,
}) => {
  const styles = useStyles();
  const lifeCycleHeartbeatStatus: StatusEnum = getHeartbeatStatus(heartbeatStatus, lifecycleState);
  const Icon = Icons[lifeCycleHeartbeatStatus] ?? Icons.Unknown;

  const iconStyles: Record<StatusCondition['status'], Record<TimeNodeLifecycleState, string>> = {
    True: {
      Up: styles.onlineIcon,
      Onboarding: styles.onboardingIcon,
      Offboarding: styles.offboardingIcon,
      Upgrading: styles.upgradingIcon,
      WaitForActivation: styles.onlineIcon,
      Activating: styles.onlineIcon,
      Rebooting: styles.onlineIcon,
      Rollback: styles.upgradingIcon,
    },
    False: {
      Up: styles.offlineIcon,
      Onboarding: styles.rebootingIcon,
      Offboarding: styles.rebootingIcon,
      Upgrading: styles.rebootingIcon,
      WaitForActivation: styles.rebootingIcon,
      Activating: styles.rebootingIcon,
      Rebooting: styles.rebootingIcon,
      Rollback: styles.rebootingIcon,
    },
    Unknown: {
      Up: styles.unknownIcon,
      Onboarding: styles.unknownIcon,
      Offboarding: styles.unknownIcon,
      Upgrading: styles.unknownIcon,
      WaitForActivation: styles.unknownIcon,
      Activating: styles.unknownIcon,
      Rebooting: styles.unknownIcon,
      Rollback: styles.unknownIcon,
    },
  };

  const iconLabel = HeartbeatStatusLabels[lifeCycleHeartbeatStatus] ?? HeartbeatStatusLabels.Unknown;
  const onlineStatus = heartbeatStatus === 'True' ? 'Online' : 'Offline';
  const statusSuffix: Record<StatusEnum, string> = {
    True: '',
    False: '',
    Unknown: '',
    Offboarding: ` (${onlineStatus})`,
    Onboarding: ` (${onlineStatus})`,
    Upgrading: ` (${onlineStatus})`,
    WaitForActivation: ` (${onlineStatus})`,
    Activating: ` (${onlineStatus})`,
    Rebooting: ` (${onlineStatus})`,
    Rollback: ` (${onlineStatus})`,
  };
  let lastObservation = 'N/A';
  if (heartbeatTime && heartbeatTime !== NEVER) {
    lastObservation = DateTime.fromISO(heartbeatTime).toRelative() ?? 'N/A';
  }
  const iconProps = {
    className: iconStyles[heartbeatStatus ?? 'Unknown'][lifecycleState ?? 'Up'],
    titleAccess: `${iconLabel}, last observation: ${lastObservation}${statusSuffix[lifeCycleHeartbeatStatus]}`,
    ['data-heartbeat-status']: heartbeatStatus,
    fontSize: 'small' as const,
  };

  if (
    offlineOnly === true &&
    !['False', 'Offboarding', 'Onboarding', 'Unknown'].includes(heartbeatStatus ?? 'Unknown')
  ) {
    return null;
  }

  return iconOnly ? (
    <Icon {...iconProps} />
  ) : (
    <Chip
      variant="default"
      color="default"
      className={styles.chip}
      icon={<Icon {...iconProps} />}
      label={iconLabel}
      title={iconProps.titleAccess}
    />
  );
};
