import { GnssConfig, PPSInConfig, PtpReceiver, TimeTransferSpec } from '@netinsight/crds-timetransfer';
import { ValueOf } from 'type-fest';

export const CONTROL_STATES = {
  Holdover: '0',
  AbsTimeSymmetric: '1',
  RelTime: '2',
  AbsTime: '3',
} as const;

export const SYNC_SOURCE_NAMES = {
  gnss: 'gnss',
  ppsIn: 'pps_in',
  osc: 'osc',
  syncIn: 'sync_in',
  ptp1: 'ptp-recv-1',
  ptp2: 'ptp-recv-2',
} as const;

export type SyncSourceName = ValueOf<typeof SYNC_SOURCE_NAMES>;

export const EXTERNAL_SYNC_SOURCES = [
  SYNC_SOURCE_NAMES.gnss,
  SYNC_SOURCE_NAMES.ppsIn,
  SYNC_SOURCE_NAMES.ptp1,
  SYNC_SOURCE_NAMES.ptp2,
] as const;

export const EXTERNAL_SYNC_SOURCE_CONFIGS: Record<
  (typeof EXTERNAL_SYNC_SOURCES)[number],
  (config: TimeTransferSpec) => GnssConfig | PPSInConfig | PtpReceiver | undefined
> = {
  [SYNC_SOURCE_NAMES.gnss]: (config: TimeTransferSpec) => config.gnss,
  [SYNC_SOURCE_NAMES.ppsIn]: (config: TimeTransferSpec) => config.ppsIn,
  [SYNC_SOURCE_NAMES.ptp1]: (config: TimeTransferSpec) => config.ptpReceiver?.instances?.[0],
  [SYNC_SOURCE_NAMES.ptp2]: (config: TimeTransferSpec) => config.ptpReceiver?.instances?.[1],
};

export const ALARM_SEVERITY_ORDER = ['warning', 'minor', 'major', 'critical'];
