import React, { FunctionComponent, ReactNode } from 'react';
import {
  InputAdornment,
  makeStyles,
  TextField as MuiTextField,
  StandardTextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import { InputTooltip } from '../InputTooltip';
import classNames from 'classnames';

const useStyles = makeStyles(
  () => ({
    textFieldContainer: {
      maxWidth: '20rem',
    },
    selectIconWithDescription: {
      ['& select + svg']: {
        right: '2rem',
      },
    },
  }),
  { name: 'Neti' },
);

export type TextFieldProps = {
  description?: ReactNode;
  unit?: string;
  rootClassName?: string;
} & MuiTextFieldProps;

export const TextField: FunctionComponent<TextFieldProps> = ({
  description,
  fullWidth,
  rootClassName,
  unit,
  ...props
}) => {
  const styles = useStyles();
  return (
    <MuiTextField
      variant="standard"
      fullWidth={fullWidth}
      {...props}
      InputProps={{
        ...(props.InputProps ?? {}),
        ...(description || unit
          ? {
              endAdornment: (
                <>
                  {unit ? <InputAdornment position="end">{unit}</InputAdornment> : null}
                  {description ? <InputTooltip text={description} /> : null}
                </>
              ),
            }
          : {}),
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        shrink: true,
      }}
      classes={{
        root: classNames(rootClassName, {
          [styles.textFieldContainer]: !fullWidth && !rootClassName,
          [styles.selectIconWithDescription]: !!description && props.select === true,
        }),
      }}
    />
  );
};
